import { Link } from '@tanstack/react-router'

const NAVBAR_LINKS = [
  {
    href: '/jobs',
    label: 'Jobs',
  },
  // {
  //   href: '/applicants',
  //   label: 'Applicants',
  // },
  // {
  //   href: '/tasks',
  //   label: 'Tasks',
  // },
  // {
  //   href: '/statistics',
  //   label: 'Statistics',
  // },
  // {
  //   href: '/settings',
  //   label: 'Settings',
  // },
]

export function Navbar() {
  return (
    <nav className="flex gap-x-8 overflow-hidden">
      {NAVBAR_LINKS.map(({ href, label }) => {
        return (
          <Link
            activeProps={{
              className: 'opacity-100',
            }}
            className="relative flex items-center text-sm tracking-wider text-primary-foreground transition-opacity hover:opacity-100"
            inactiveProps={{
              className: 'opacity-80',
            }}
            key={href}
            to={href}
          >
            {label}
          </Link>
        )
      })}
    </nav>
  )
}
