import { ReactNode } from 'react'

import { LucideIcon } from 'lucide-react'

import { cn } from '@/lib/utils'

import { Text } from './ui/text'

interface EmptyStateProps {
  icon: LucideIcon
  subtitle?: string
  title: string
  className?: string
  children?: ReactNode
}

export function EmptyState({ children, className, icon: Icon, subtitle, title }: EmptyStateProps) {
  return (
    <div className={cn('flex flex-col items-center justify-center gap-y-2 rounded border p-8 bg-white', className)}>
      <div className="flex items-center rounded-full bg-primary/5 p-4">
        <Icon className="text-primary" size={32} strokeWidth={1} />
      </div>
      <div className="text-center">
        <Text weight="medium">{title}</Text>
        {subtitle && (
          <Text size="sm" weight="light">
            {subtitle}
          </Text>
        )}
      </div>
      {children}
    </div>
  )
}
