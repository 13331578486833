import { queryOptions } from '@tanstack/react-query'

import {
  getJobs,
  getJobsBoard,
  getJobsBoardAutomations,
  getJobsDescription,
  getJobsInformations,
  getJobsInternalInformations,
  getJobsRecruiters,
  getJobsRefusalReasons,
  getJobsSearch,
  getJobsStages,
  getJobsStagesAutomation,
  getJobsStagesAutomations,
  getJobsStatus,
} from '@/api/jobs'
import { JobFilterValues } from '@/schemas/job'

/*
 ** Query Options
 */
// GET /jobs
export const jobsQuery = (filters: JobFilterValues) =>
  queryOptions({
    queryFn: () => getJobs(filters),
    queryKey: ['jobs', filters],
  })

// GET /jobs/${jobId}/board
export const jobsBoardQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsBoard(jobId),
    queryKey: ['jobsBoard', jobId],
  })

// GET /jobs/${jobId}/board/automations
export const jobsBoardAutomationsQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsBoardAutomations(jobId),
    queryKey: ['jobsBoardAutomations', jobId],
  })

// GET /jobs/${jobId}/info
export const jobsInformationsQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsInformations(jobId),
    queryKey: ['jobsInformations', jobId],
  })

// GET /jobs/${jobId}/description
export const jobsDescriptionQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsDescription(jobId),
    queryKey: ['jobsDescription', jobId],
  })

// GET /jobs/${jobId}/stages
export const jobsStagesQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsStages(jobId),
    queryKey: ['jobsStages', jobId],
  })

// GET /jobs/${jobId}/recruiters
export function jobsRecruitersQuery(jobId: string) {
  return queryOptions({
    queryFn: () => getJobsRecruiters(jobId),
    queryKey: ['jobsRecruiters', jobId],
  })
}

// GET /jobs/${jobId}/internal-informations
export const jobsInternalInformationsQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsInternalInformations(jobId),
    queryKey: ['jobsInternalInformations', jobId],
  })

// GET /jobs/${jobId}/refusal-reasons
export const jobsRefusalReasonsQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsRefusalReasons(jobId),
    queryKey: ['jobsRefusalReasons', jobId],
  })

// GET /jobs/${jobId}/stages/${stageId}/automations
export const jobsStagesAutomations = (jobId: string, stageId?: string) =>
  queryOptions({
    enabled: !!stageId,
    queryFn: () => getJobsStagesAutomations(jobId, stageId),
    queryKey: ['jobsStagesAutomations', jobId, stageId],
    // TODO: it's a test for now, move magic number to a constant if it's a good idea
    staleTime: 60000,
  })

// GET /jobs/${jobId}/stages/${stageId}/automations/${automationId}
export const jobsStagesAutomation = <T,>(jobId: string, stageId?: string, automationId?: string) =>
  queryOptions({
    enabled: !!automationId && !!stageId,
    queryFn: () => getJobsStagesAutomation<T>(jobId, stageId, automationId),
    queryKey: ['jobsStagesAutomation', jobId, stageId, automationId],
  })

// GET /jobs/search
export const jobsSearchQuery = (query?: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => getJobsSearch(query),
    queryKey: ['jobsSearch', query],
  })

// GET /jobs/${jobId}/status
export const jobsStatusQuery = (jobId: string) =>
  queryOptions({
    queryFn: () => getJobsStatus(jobId),
    queryKey: ['jobsStatus', jobId],
  })
