/*
 ** Mutations
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { cancelInterview, finishInterviews, rescheduleInterview, updateInterviewsNote } from '@/api/interviews'
import { applicationsLastActivitiesQuery } from '@/hooks/use-applications-queries'
import { UpdateInterviewsBody } from '@/types/interviews/note'

import { applicationsInterviewsQuery } from './use-applications-queries'

// PUT /interviews/:interviewId/done
export function useFinishInterview(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ interviewId }: { interviewId: string }) => finishInterviews(interviewId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsInterviewsQuery(applicationId).queryKey })
      await queryClient.invalidateQueries({ queryKey: applicationsLastActivitiesQuery(applicationId).queryKey })
    },
  })
}
// PUT /interviews/:interviewId/note
export function useUpdateInterviewsNote(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, interviewId }: { interviewId: string; body: UpdateInterviewsBody }) =>
      updateInterviewsNote(interviewId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsInterviewsQuery(applicationId).queryKey })
      await queryClient.invalidateQueries({ queryKey: applicationsLastActivitiesQuery(applicationId).queryKey })
    },
  })
}

// PUT /interviews/:interviewId/cancel
export function useCancelInterview(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (interviewId: string) => cancelInterview(interviewId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsInterviewsQuery(applicationId).queryKey })
    },
  })
}

// PUT /interviews/:interviewId/reschedule
export function useRescheduleInterview(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (interviewId: string) => rescheduleInterview(interviewId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsInterviewsQuery(applicationId).queryKey })
    },
  })
}
