import './index.css'

import { StrictMode } from 'react'

import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'

import { Toaster } from '@/components/ui/sonner'

import App from './app'
import { AuthProvider } from './providers/auth-provider'
import { queryClient, router } from './router'

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

Sentry.init({
  dsn: 'https://b0efc4cd312f367fad23d99e7174aa68@o4507169861206016.ingest.de.sentry.io/4507169997389904',
  enabled: !import.meta.env.DEV,
  // environment: import.meta.env.VITE_ENVIRONMENT as string,
  integrations: [Sentry.replayIntegration()],

  replaysOnErrorSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
})

// Render the app
const rootElement = document.getElementById('app')!

if (!rootElement.innerHTML) {
  const root = createRoot(rootElement)
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
        <Toaster />
      </QueryClientProvider>
    </StrictMode>,
  )
}
