import { Bot, User, WandSparkles } from 'lucide-react'

import { cn } from '@/lib/utils'
import { PerformerType } from '@/types/application/activities'

const ICONS = {
  AGENT: {
    className: 'bg-muted text-muted-foreground',
    icon: Bot,
  },
  AUTOMATION: {
    className: 'bg-muted text-muted-foreground',
    icon: WandSparkles,
  },
  RECRUITER: {
    className: 'bg-primary/5 text-primary',
    icon: User,
  },
  USER: {
    className: 'bg-yellow/20 text-yellow',
    icon: User,
  },
}

interface ActivityAvatarProps {
  performerType: PerformerType
}

export function ActivityAvatar({ performerType }: ActivityAvatarProps) {
  const Icon = ICONS[performerType].icon

  return (
    <div
      className={cn(
        'flex size-6 shrink-0 items-center justify-center rounded-full bg-muted text-muted-foreground',
        ICONS[performerType].className,
      )}
    >
      <Icon size={16} />
    </div>
  )
}
