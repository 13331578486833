import { Children } from 'react'

import { Check, ChevronsUpDown, Loader2, X } from 'lucide-react'
import ReactSelect, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueRemoveProps,
  OptionProps,
  Props as ReactSelectProps,
  ValueContainerProps,
} from 'react-select'
import ReactCreatableSelect, { CreatableProps } from 'react-select/creatable'

import { cn } from '@/lib/utils'

import { Text } from './ui/text'

const baseInputStyles = 'px-1'
const containerStyles = 'bg-white'
const controlStyles = ({ isDisabled, isFocused }: { isDisabled?: boolean; isFocused: boolean }) =>
  cn(
    'flex min-h-9 w-full items-center ring-offset-background justify-between whitespace-nowrap rounded-md border border-input bg-transparent py-1 px-2 text-sm shadow-sm',
    isFocused && 'ring-2 ring-ring ring-offset-2 outline-none',
    isDisabled && 'cursor-not-allowed opacity-50',
  )
const inputStyles = baseInputStyles
const valueContainerStyles = 'gap-2'
const singleValueStyles = baseInputStyles
const dropdownIndicatorStyles = 'opacity-50 text-muted-foreground'
const placeholderStyles = cn(baseInputStyles, ' text-muted-foreground/50 truncate')
const multiValueStyles = 'bg-muted shrink-0 rounded-md overflow-hidden'
const multiValueLabelStyles = 'px-1'
const multiValueRemoveStyles =
  'size-5 items-center flex justify-center transition-colors hover:bg-foreground hover:text-white'
const indicatorsContainerStyles = ''
const clearIndicatorStyles = 'cursor-pointer opacity-50 text-muted-foreground'
const menuStyles = 'mt-1 bg-white border border-input shadow rounded-md text-sm'
const menuListStyles = 'flex flex-col gap-y-1 p-1'
const optionStyles = ({ isDisabled, isFocused }: { isDisabled?: boolean; isFocused: boolean }) =>
  cn('cursor-pointer px-2 py-1 rounded-md', isFocused && 'bg-muted', isDisabled && 'opacity-30')

function DropdownIndicator<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronsUpDown size={16} />
    </components.DropdownIndicator>
  )
}

function ClearIndicator<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: ClearIndicatorProps<Option, IsMulti, Group>,
) {
  return (
    <components.ClearIndicator {...props}>
      <X size={16} />
    </components.ClearIndicator>
  )
}

function ValueContainer<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: ValueContainerProps<Option, IsMulti, Group>,
) {
  return <components.ValueContainer {...props} />
}

function HideSelectedOptionsOnInputValueContainer<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: ValueContainerProps<Option, IsMulti, Group>) {
  const { selectProps } = props
  const input = Children.toArray(props.children).pop()
  const placeholder = selectProps.placeholder
  const selectedCount = props.getValue().length
  const placeholderVisible = selectedCount === 0 && !selectProps.inputValue

  return (
    <components.ValueContainer {...props}>
      <div className="flex">
        <div className="absolute px-1">{placeholderVisible && placeholder}</div>
        {props.hasValue && (
          <div className="px-1">
            <Text size="sm" weight="semibold">
              {selectedCount} selected
            </Text>
          </div>
        )}
        {input}
      </div>
    </components.ValueContainer>
  )
}

function MultiValueRemove<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>,
) {
  return (
    <components.MultiValueRemove {...props}>
      <X size={16} />
    </components.MultiValueRemove>
  )
}

function Option<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: OptionProps<Option, IsMulti, Group>,
) {
  const { data, isMulti, isSelected, label } = props
  const { label: createLabel } = data as { label?: string }

  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between">
        <span>{label || createLabel}</span>
        {isMulti && (
          <div
            className={cn(
              'h-4 w-4 shrink-0 rounded-md border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground disabled:opacity-10',
            )}
            data-state={isSelected ? 'checked' : undefined}
          >
            {isSelected && <Check className="flex items-center justify-center text-current" size={14} />}
          </div>
        )}
        {!isMulti && isSelected && <Check size={16} />}
      </div>
    </components.Option>
  )
}

function LoadingIndicator() {
  return <Loader2 className="animate-spin opacity-50" size={16} />
}

type SelectProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = {
  hideSelectedOptionsOnInput?: boolean
} & ReactSelectProps<Option, IsMulti, Group>

export function Select<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  hideSelectedOptionsOnInput,
  isMulti,
  menuPosition = 'fixed',
  ...props
}: SelectProps<Option, IsMulti, Group>) {
  // this is a workaround for the issue where the select loses focus when it's inside a radix ui dialog (modal, sheet, etc)
  // see: https://github.com/JedWatson/react-select/issues/5732
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const element = event.relatedTarget as HTMLElement
    if (element?.getAttribute('role') === 'dialog') return
    element?.focus()
  }
  return (
    <ReactSelect
      onBlur={onBlur}
      {...props}
      classNames={{
        clearIndicator: () => clearIndicatorStyles,
        container: () => containerStyles,
        control: ({ isDisabled, isFocused }) => controlStyles({ isDisabled, isFocused }),
        dropdownIndicator: () => dropdownIndicatorStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        input: () => inputStyles,
        menu: () => menuStyles,
        menuList: () => menuListStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        option: ({ isDisabled, isFocused }) => optionStyles({ isDisabled, isFocused }),
        placeholder: () => placeholderStyles,
        singleValue: () => singleValueStyles,
        valueContainer: () => valueContainerStyles,
      }}
      closeMenuOnSelect={!isMulti}
      components={{
        ClearIndicator,
        DropdownIndicator,
        LoadingIndicator,
        MultiValueRemove,
        Option,
        ValueContainer: hideSelectedOptionsOnInput ? HideSelectedOptionsOnInputValueContainer : ValueContainer,
        ...props.components,
      }}
      hideSelectedOptions={false}
      isMulti={isMulti}
      menuPosition={menuPosition}
      styles={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        control: ({ minHeight, ...provided }) => ({
          ...provided,
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }) => ({
          ...provided,
        }),
      }}
      unstyled
    />
  )
}

type CreatableSelectProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = {
  hideSelectedOptionsOnInput?: boolean
} & CreatableProps<Option, IsMulti, Group>

export function CreatableSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ hideSelectedOptionsOnInput, isMulti, ...rest }: CreatableSelectProps<Option, IsMulti, Group>) {
  return (
    <ReactCreatableSelect
      allowCreateWhileLoading={false}
      classNames={{
        clearIndicator: () => clearIndicatorStyles,
        container: () => containerStyles,
        control: ({ isDisabled, isFocused }) => controlStyles({ isDisabled, isFocused }),
        dropdownIndicator: () => dropdownIndicatorStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        input: () => inputStyles,
        menu: () => menuStyles,
        menuList: () => menuListStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        option: ({ isDisabled, isFocused }) => optionStyles({ isDisabled, isFocused }),
        placeholder: () => placeholderStyles,
        singleValue: () => singleValueStyles,
        valueContainer: () => valueContainerStyles,
      }}
      closeMenuOnSelect={!isMulti}
      components={{
        ClearIndicator,
        DropdownIndicator,
        LoadingIndicator,
        MultiValueRemove,
        Option,
        ValueContainer: hideSelectedOptionsOnInput ? HideSelectedOptionsOnInputValueContainer : ValueContainer,
        ...rest.components,
      }}
      hideSelectedOptions={false}
      isMulti={isMulti}
      unstyled
      {...rest}
    />
  )
}
