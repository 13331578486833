import { queryOptions } from '@tanstack/react-query'

import { getConfig } from '@/api/config'

/**
 * Query options
 */

// GET /config
export const configQuery = queryOptions({
  queryFn: getConfig,
  queryKey: ['config'],
  staleTime: Infinity,
})
