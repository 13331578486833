export enum ActivityType {
  APPLICANT_ADDED = 'APPLICANT_ADDED',
  COMMENT = 'COMMENT',
  HISTORY = 'HISTORY',
  INTERVIEW = 'INTERVIEW',
  INTERVIEW_CREATED = 'INTERVIEW_CREATED',
  INTERVIEW_INVITE = 'INTERVIEW_INVITE',
  INTERVIEW_NOTE = 'INTERVIEW_NOTE',
  INTERVIEW_PICK_SLOT = 'INTERVIEW_PICK_SLOT',
  JOB_CLOSED = 'JOB_CLOSED',
  JOB_FILLED = 'JOB_FILLED',
  QUALIFICATION = 'QUALIFICATION',
  STAGE_UPDATED = 'STAGE_UPDATED',
  TASK_DONE = 'TASK_DONE',
  TASK_TO_DO = 'TASK_TO_DO',
  EMAIL_SENT = 'EMAIL_SENT',
}

export enum PerformerType {
  AGENT = 'AGENT',
  AUTOMATION = 'AUTOMATION',
  RECRUITER = 'RECRUITER',
  USER = 'USER',
}

export type CommentContent = {
  commentId: string
  comment: string
}

export type InterviewCreatedContent = {
  scheduledAt: string
}

export type InterviewInviteContent = {
  emailId: string
}

export type InterviewNoteContent = {
  note: string
}

export type TaskDoneContent = {
  taskId: string
  taskContent: string
}

export type TaskToDoContent = {
  dueAt: string
  taskId: string
  status: string
  taskContent: string
}

export type EmailSentContent = {
  body: string
  subject: string
  message: string
}

type ActivityContentMap = {
  [ActivityType.APPLICANT_ADDED]: null
  [ActivityType.COMMENT]: CommentContent
  [ActivityType.HISTORY]: null
  [ActivityType.INTERVIEW]: null
  [ActivityType.INTERVIEW_CREATED]: InterviewCreatedContent
  [ActivityType.INTERVIEW_INVITE]: InterviewInviteContent
  [ActivityType.INTERVIEW_NOTE]: InterviewNoteContent
  [ActivityType.INTERVIEW_PICK_SLOT]: null
  [ActivityType.JOB_CLOSED]: null
  [ActivityType.JOB_FILLED]: null
  [ActivityType.QUALIFICATION]: null
  [ActivityType.STAGE_UPDATED]: null
  [ActivityType.TASK_DONE]: TaskDoneContent
  [ActivityType.TASK_TO_DO]: TaskToDoContent
  [ActivityType.EMAIL_SENT]: EmailSentContent
}

export type ActivitiesApplicationItem = {
  content: ActivityContentMap[ActivityType]
  createdAt: string
  message: string
  performerId: string
  performerType: PerformerType
  type: ActivityType
}

export type ActivitiesLastApplication = {
  hasMore: boolean
  items: ActivitiesApplicationItem[]
}

export type ActivitiesApplication = ActivitiesApplicationItem
