import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { Organization, OrganizationCreateOrUpdateBody, OrganizationCreateOrUpdateResponse } from '@/types/organization'

export function getOrganizations(view?: 'minimal') {
  return get<Organization[]>('/organizations', {
    params: {
      output: view,
    },
  })
}

export function createOrganization(body: OrganizationCreateOrUpdateBody) {
  return post<OrganizationCreateOrUpdateBody, OrganizationCreateOrUpdateResponse>('/organizations', body)
}

export function updateOrganization(id: string, body: OrganizationCreateOrUpdateBody) {
  return put<OrganizationCreateOrUpdateBody, OrganizationCreateOrUpdateResponse>(`/organizations/${id}`, body)
}
