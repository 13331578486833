import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { MultiValue, SingleValue } from 'react-select'

import { Select } from '@/components/select'
import { configQuery } from '@/hooks/use-config-queries'
import { locationsSearchQuery } from '@/hooks/use-location-queries'
import { Location } from '@/types/location/location'

interface LocationSelectProps {
  placeholder?: string
  value?: MultiValue<Location> | SingleValue<Location>
  onChange: (newValue: MultiValue<Location> | SingleValue<Location>) => void
}

export const LocationSelect = ({ onChange, placeholder = 'Select location...', value }: LocationSelectProps) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 300)
  const { data: config } = useQuery(configQuery)
  const { data: locations, isLoading } = useQuery(locationsSearchQuery(debouncedQuery))
  const isMulti = Array.isArray(value)

  return (
    <Select
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isLoading={isLoading}
      isMulti={isMulti}
      onChange={onChange}
      onInputChange={setQuery}
      options={locations ?? config?.locations}
      placeholder={placeholder}
      value={value}
    />
  )
}
