import { Status } from '@/types/job'

export const HEXADECIMAL_COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

export const INPUT_PHONE_REGEX = /^\+[1-9]\d{1,14}$/

export const JOB_STATUSES_COLORS: Record<Status, string> = {
  CLOSED: 'red',
  DRAFT: 'black',
  FILLED: 'green',
  OPEN: 'yellow',
  PAUSED: 'purple',
}
