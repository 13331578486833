import { queryOptions } from '@tanstack/react-query'

import { getJobTitlesSearch } from '@/api/jobTitles'

/**
 * Query options
 */

// GET /job-titles/search
export const jobTitlesSearchQuery = (query: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => getJobTitlesSearch(query),
    queryKey: ['jobTitles', query],
  })
