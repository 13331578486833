import { useState } from 'react'

import { Ban } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet'
import { useBulkActions } from '@/hooks/use-bulk-actions'

import { RefusalStrategyForm } from './refusal-strategy-form'

export function RefuseAction() {
  const [open, onOpenChange] = useState(false)
  const { selected } = useBulkActions()
  const hasSelected = selected.length > 0

  return (
    <>
      <Button disabled={!hasSelected} onClick={() => onOpenChange(true)} variant="destructive">
        <Ban size={16} />
        Refuse
      </Button>
      <Sheet onOpenChange={onOpenChange} open={open}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Refuse {selected.length > 1 ? 'these applicants' : 'this applicant'}</SheetTitle>
          </SheetHeader>
          <div className="flex flex-col gap-2">
            <RefusalStrategyForm onClose={() => onOpenChange(false)} />
          </div>
        </SheetContent>
      </Sheet>
    </>
  )
}
