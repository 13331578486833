import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/auth')({
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated()) {
      throw redirect({
        to: search?.redirect ?? '/',
      })
    }
  },
  component: Index,
  validateSearch: z.object({
    redirect: z.string().catch('/').optional(),
  }),
})

function Index() {
  return (
    <div className="flex h-screen">
      <div className="relative flex flex-1 flex-col items-center justify-center gap-y-20 bg-primary/80">
        <img
          alt="Wind Turbine"
          className="absolute left-0 top-0 z-[-1] size-full object-cover"
          src="/auth-side-cover-eol.jpg"
        />
        <img alt="Logo Aventa" className="fixed left-5 top-5" height={200} src="/logo.svg" width={100} />
        <div className="relative flex flex-col">
          <span className="text-xl font-extralight uppercase tracking-widest text-white">Because our future is</span>
          <span className="text-8xl font-extrabold uppercase tracking-widest text-white">blue</span>
        </div>
      </div>
      <div className="flex flex-1 items-center justify-center bg-zinc-50">
        <Outlet />
      </div>
    </div>
  )
}
