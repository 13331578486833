import { isToday } from 'date-fns'
import { CalendarCheckIcon, CalendarIcon, CalendarX2Icon } from 'lucide-react'

import { Text } from '@/components/ui/text'
import { getDistanceToNowStrict, isAfterToday } from '@/utils/date'

function displayAvailability(availability?: string) {
  if (!availability) {
    return (
      <>
        <CalendarIcon className="text-muted-foreground" size={16} />
        <Text size="xs" truncate>
          -
        </Text>
      </>
    )
  }
  if (isToday(availability)) {
    return (
      <>
        <CalendarCheckIcon className="shrink-0 text-green" size={16} />
        <Text size="xs" truncate>
          Now
        </Text>
      </>
    )
  }
  if (isAfterToday(availability)) {
    return (
      <>
        <CalendarX2Icon className="shrink-0 text-destructive" size={16} />
        <Text size="xs" truncate>
          {getDistanceToNowStrict(availability)}
        </Text>
      </>
    )
  }

  return (
    <>
      <CalendarCheckIcon className="shrink-0 text-green" size={16} />
      <Text size="xs" truncate>
        {getDistanceToNowStrict(availability)}
      </Text>
    </>
  )
}

export function Availability({ value }: { value?: string }) {
  return <div className="flex items-center gap-x-1">{displayAvailability(value)}</div>
}
