import { useNavigate, useParams } from '@tanstack/react-router'
import { FolderOpen } from 'lucide-react'

import { EmptyState } from '@/components/empty-state'
import { FilePreview } from '@/components/file-preview'
import { Button } from '@/components/ui/button'
import { ApplicantInfo } from '@/types/application/applicant-info'
import { MimeType } from '@/types/application/documents'

interface ResumeProps {
  data: ApplicantInfo['resume']
}

const MIME_TYPE_MAPPING: Record<string, MimeType> = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
}

export function Resume({ data }: ResumeProps) {
  const { applicationId, jobId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })

  const navigate = useNavigate({ from: '/jobs/$jobId' })

  if (!data) {
    return (
      <EmptyState icon={FolderOpen} subtitle="You can upload a resume in files tab" title="No resume">
        <Button
          onClick={async () => {
            await navigate({
              params: { applicationId, jobId },
              search: { view: 'files' },

              to: '/jobs/$jobId/applications/$applicationId',
            })
          }}
        >
          Upload a resume
        </Button>
      </EmptyState>
    )
  }

  const splitData = data.split('.')
  const name = splitData.at(-2)! // we know it exist
  const extension = splitData.at(-1)! // we know it exist

  return (
    <div className="relative h-[calc(100vh-theme(spacing.56))] w-full outline outline-muted">
      <FilePreview mimeType={MIME_TYPE_MAPPING[extension]} name={name} url={data} />
    </div>
  )
}
