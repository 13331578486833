import { getRefusalReasons } from '@/api/refusal-reasons'

/**
 * Query options
 */

// GET /refusal-reasons
export const refusalReasonsQuery = {
  queryFn: getRefusalReasons,
  queryKey: ['refusalReasons'],
  staleTime: Infinity,
}
