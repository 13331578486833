import { BrainIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { getApplicationMatchingColor } from '@/utils/color'

interface MatchScoreProps {
  value?: number
}

export function MatchScore({ value }: MatchScoreProps) {
  if (!value) return null

  return (
    <div className={cn('flex items-center gap-x-1', `text-${getApplicationMatchingColor(value)}`)}>
      <BrainIcon size={16} />
      <span className="text-xs">{value}%</span>
    </div>
  )
}
