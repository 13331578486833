import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'

import { jobTitlesSearchQuery } from '@/hooks/use-job-titles-queries'

import { CreatableSelect } from '../select'

interface JobTitleSelectProps {
  value: string
  onChange: (value?: string) => void
  placeholder?: string
}

export const JobTitleSelect = ({
  onChange,
  placeholder = 'Search a job title or create a new one...',
  value,
}: JobTitleSelectProps) => {
  const [query, setQuery] = useState(value)
  const debouncedQuery = useDebounce(query, 300)
  const { data: jobTitles, isLoading } = useQuery(jobTitlesSearchQuery(debouncedQuery))

  return (
    <CreatableSelect
      formatCreateLabel={(value) => (
        <>
          <span className="rounded bg-muted-foreground px-1 py-0.5 text-white">Create new job title: </span>&nbsp;
          {value}
        </>
      )}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isClearable
      isLoading={isLoading}
      noOptionsMessage={() => null}
      onChange={(value) => onChange(value?.name)}
      onCreateOption={(value) => onChange(value)}
      onInputChange={(value) => {
        if (value) {
          setQuery(value)
        }
      }}
      options={jobTitles}
      placeholder={placeholder}
      value={value ? jobTitles?.find((opt) => opt.name === value) ?? { id: value, name: value } : undefined}
    />
  )
}
