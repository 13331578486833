import { useState } from 'react'

import { addDays } from 'date-fns'
import { Pencil } from 'lucide-react'

import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet'
import { Text } from '@/components/ui/text'
import { JobBoardApplication } from '@/types/application/job-board'
import { isBeforeToday } from '@/utils/date'

import { CountDown } from './count-down'
import { UpdateForm } from './update-form'

interface UpdateActionProps {
  application: JobBoardApplication
}

export function UpdateAction({ application }: UpdateActionProps) {
  const [open, onOpenChange] = useState(false)

  const onUpdate = () => {
    onOpenChange(true)
  }

  const refusedDate = addDays(application.refusedAt!, application.emailSendingDelay ?? 0)
  const isOutdated = isBeforeToday(refusedDate)

  return (
    <>
      <div className="flex items-center justify-center gap-x-2 truncate">
        <Text size="xs" truncate variant={isOutdated ? 'red' : 'muted'}>
          {application.refusalReason.name}
        </Text>
        {!isOutdated && <Pencil className="cursor-pointer text-muted-foreground" onClick={onUpdate} size={12} />}
      </div>

      <Sheet onOpenChange={onOpenChange} open={open}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Update Refusal Reason for this application</SheetTitle>
          </SheetHeader>
          <div className="flex flex-col gap-2">
            <UpdateForm
              applicationId={application.id}
              emailSendingDelay={application.emailSendingDelay}
              emailTemplateId={application.emailTemplate?.id}
              onClose={() => onOpenChange(false)}
              refusalReasonId={application.refusalReason.id}
            />
          </div>
        </SheetContent>
      </Sheet>
      {!isOutdated && (
        <div className="ml-auto shrink-0">
          <CountDown refusedDate={refusedDate} />
        </div>
      )}
    </>
  )
}
