export const getApplicationMatchingColor = (value: number) => {
  if (value <= 25) return 'red'
  if (value <= 50) return 'yellow'
  if (value <= 75) return 'primary'
  return 'green'
}

export const getQualificationMatchingColor = (value: number) => {
  const percent = value * 100
  if (percent <= 25) return 'red'
  if (percent <= 50) return 'yellow'
  if (percent <= 75) return 'primary'
  return 'green'
}
