import { queryOptions, useMutation, useQueryClient } from '@tanstack/react-query'

import { createRecruiter, getRecruiters, updateRecruiter } from '@/api/recruiters'
import { RecruiterCreateOrUpdateBody } from '@/types/recruiter'

/**
 * Query options
 */

// GET /recruiters
export const recruitersQuery = queryOptions({
  queryFn: () => getRecruiters(),
  queryKey: ['recruiters'],
})

/**
 * Mutations
 */

// POST /recruiters
export const useCreateRecruiter = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: RecruiterCreateOrUpdateBody) => createRecruiter(body),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: recruitersQuery.queryKey })
    },
  })
}

// PUT /recruiters/:id
export const useUpdateRecruiter = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: RecruiterCreateOrUpdateBody) => updateRecruiter(id, body),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: recruitersQuery.queryKey })
    },
  })
}
