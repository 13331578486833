import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  createApplicationsDocuments,
  createApplicationsInterviews,
  updateApplicationsDocuments,
} from '@/api/applications'
import { applicationsActivitiesQuery, applicationsLastActivitiesQuery } from '@/hooks/use-applications-queries'
import { UpdateApplicationsDocumentBody } from '@/types/application/documents'
import { CreateApplicationInterviewBody } from '@/types/application/interview'

import { applicationsDocumentsQuery, applicationsInterviewsQuery } from './use-applications-queries'

/**
 * Mutations
 */

// POST /applications/:applicationId/interviews
export function useCreateApplicationsInterviews(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body }: { body: CreateApplicationInterviewBody }) =>
      createApplicationsInterviews(applicationId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsInterviewsQuery(applicationId).queryKey })
      await queryClient.invalidateQueries({ queryKey: applicationsLastActivitiesQuery(applicationId).queryKey })
      await queryClient.invalidateQueries({ queryKey: applicationsActivitiesQuery(applicationId).queryKey })
    },
  })
}

// PUT /applications/:applicationId/documents/:documentId
export function useUpdateApplicationsDocuments(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body, documentId }: { body: UpdateApplicationsDocumentBody; documentId: string }) =>
      updateApplicationsDocuments(applicationId, documentId, body),
    onSuccess: (data) => {
      queryClient.setQueryData(applicationsDocumentsQuery(applicationId).queryKey, (oldData) => {
        if (!oldData) return oldData

        return oldData.map((document) => {
          if (document.id === data.id) {
            return {
              ...document,
              ...data,
            }
          }

          return document
        })
      })
    },
  })
}

// POST /applications/:applicationId/documents
export function useCreateApplicationsDocuments(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body }: { body: FormData }) => createApplicationsDocuments(applicationId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsDocumentsQuery(applicationId).queryKey })
    },
  })
}
