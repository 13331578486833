import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useSearch } from '@tanstack/react-router'
import axios, { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { createPassword } from '@/api/auth'
import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useAuth } from '@/hooks/use-auth'
import { ResetPasswordValues, resetPasswordSchema } from '@/schemas/auth'

export function CreatePasswordForm() {
  const navigate = useNavigate()
  const { email, token } = useSearch({ from: '/auth/create-password' })
  const { login } = useAuth()

  const form = useForm<ResetPasswordValues>({
    defaultValues: {
      confirmation: '',
      email,
      password: '',
      token,
    },
    resolver: zodResolver(resetPasswordSchema),
  })

  const { getValues } = form

  const onSubmit = async (values: ResetPasswordValues) => {
    try {
      await createPassword(values)
      await login({ email: values.email, password: values.password })
      await navigate({ to: '/' })

      toast.success('Password created successfully.')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          const errors = (error as AxiosError<{ errors: Record<keyof ResetPasswordValues, string> }>).response?.data
            ?.errors

          if (errors) {
            Object.entries(errors).forEach(([key, value]) => {
              form.setError(key as keyof ResetPasswordValues, {
                message: value,
                type: 'manual',
              })
            })
            return
          }
        }
      }

      toast.error('An error occurred. Please try again.')
    }
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <CustomFormField
          control={form.control}
          disabled
          fieldType={FormFieldType.INPUT}
          label="Email"
          name="email"
          placeholder="aurelien@aventa.fr"
          type="email"
        />
        <CustomFormField
          control={form.control}
          fieldType={FormFieldType.INPUT}
          label="New password"
          name="password"
          placeholder="********"
          type="password"
        />
        <CustomFormField
          control={form.control}
          fieldType={FormFieldType.INPUT}
          label="Confirm new password"
          name="confirmation"
          placeholder="********"
          type="password"
        />
        <input type="hidden" value={getValues('token')} />
        <Button isLoading={form.formState.isSubmitting} size="lg" type="submit">
          Create password and login
        </Button>
      </form>
    </Form>
  )
}
