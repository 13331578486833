import { SquareCheck } from 'lucide-react'

import { Text } from '@/components/ui/text'
import { TaskDoneContent } from '@/types/application/activities'

export function TaskDone({ taskContent }: TaskDoneContent) {
  return (
    <div className="flex items-center gap-x-2">
      <SquareCheck className="shrink-0 text-primary" size={16} />
      <Text size="xs" weight="semibold">
        {taskContent}
      </Text>
    </div>
  )
}
