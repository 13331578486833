import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateTasksApplication } from '@/api/tasks'
import { applicationsActivitiesQuery, applicationsLastActivitiesQuery } from '@/hooks/use-applications-queries'
import { UpdateTasksApplicationBody } from '@/types/tasks/application'

// PUT /tasks/{taskId}/application
export const useUpdateTasksApplication = (taskId: string, applicationId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: UpdateTasksApplicationBody) => updateTasksApplication(taskId, body),
    mutationKey: ['updateTasksApplication', taskId],
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsLastActivitiesQuery(applicationId).queryKey })
      await queryClient.invalidateQueries({ queryKey: applicationsActivitiesQuery(applicationId).queryKey })
    },
  })
}
