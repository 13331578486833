import { useState } from 'react'

import { Mail } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet'
import { useBulkActions } from '@/hooks/use-bulk-actions'

import { SendEmailForm } from './send-email-form'

export function SendEmailAction() {
  const [open, onOpenChange] = useState(false)

  const { selected } = useBulkActions()
  const hasSelected = selected.length > 0

  return (
    <>
      <Button disabled={!hasSelected} onClick={() => onOpenChange(true)}>
        <Mail size={16} />
        Send email
      </Button>
      <Sheet onOpenChange={onOpenChange} open={open}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Send Email</SheetTitle>
          </SheetHeader>
          <div className="flex flex-col gap-2">
            <SendEmailForm onClose={() => onOpenChange(false)} />
          </div>
        </SheetContent>
      </Sheet>
    </>
  )
}
