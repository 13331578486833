import { PutJobInformationsValues } from '@/schemas/jobs/jobId/edit'
import { JobCreateOrUpdateInformationsBody } from '@/types/job'

export const getJobInformationValuesFormated = (
  values: PutJobInformationsValues,
): JobCreateOrUpdateInformationsBody => {
  const {
    endDate,
    experience: [experienceMin, experienceMax],
    jobLocations,
    startDate,
    ...rest
  } = values

  return {
    ...rest,
    endDate: endDate ? endDate.toISOString() : null,
    experienceMax,
    experienceMin,
    jobLocations: jobLocations.map((location) => ({
      frequency: location.frequency,
      locationId: location.location.id,
      type: location.type,
    })),
    startDate: startDate ? startDate.toISOString() : null,
  }
}
