import { queryOptions, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  createApplication,
  getApplicationJobStages,
  getApplicationsLastActivities,
  getApplicationsApplicant,
  getApplicationsApplicantInfo,
  updateApplicationsStage,
  getApplicationsActivities,
  createApplicationsComment,
  updateApplicationsApplicant,
  updateApplicationsRefuseApplication,
} from '@/api/applications'
import { jobsBoardQuery } from '@/queries/use-jobs-queries'
import { CreateApplicationCommentBody } from '@/types/application/comments'
import { CreateApplicationBody } from '@/types/application/create'
import { UpdateRefuseApplicationBody } from '@/types/application/refuse-application'
import { UpdateApplicationsApplicantBody } from '@/types/application/update-applicant'
import { ApplicationsUpdateStageBody } from '@/types/application/update-stage'

/**
 * Query options
 */

// GET /applications/:applicationId/applicant
export function applicationsApplicantQuery(applicationId: string) {
  return queryOptions({
    queryFn: () => getApplicationsApplicant(applicationId),
    queryKey: ['applicationsApplicant', applicationId],
  })
}

// GET /applications/:applicationId/job-stages
export function applicationsJobStagesQuery(applicationId: string) {
  return queryOptions({
    queryFn: () => getApplicationJobStages(applicationId),
    queryKey: ['applicationsJobStages', applicationId],
  })
}

// GET /applications/:applicationId/applicant-info
export function applicationsApplicantInfoQuery(applicationId: string) {
  return queryOptions({
    queryFn: () => getApplicationsApplicantInfo(applicationId),
    queryKey: ['applicationsApplicantInfo', applicationId],
  })
}

// GET /applications/:applicationId/activities
export function applicationsActivitiesQuery(applicationId: string) {
  return queryOptions({
    queryFn: () => getApplicationsActivities(applicationId),
    queryKey: ['applicationsActivities', applicationId],
  })
}

// GET /applications/:applicationId/activities/last
export function applicationsLastActivitiesQuery(applicationId: string) {
  return queryOptions({
    queryFn: () => getApplicationsLastActivities(applicationId),
    queryKey: ['applicationsLastActivities', applicationId],
  })
}

/**
 * Mutations
 */

// PUT /applications/:applicationId/update-stage
export function useUpdateApplicationsStage(jobId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ applicationId, body }: { applicationId: string; body: ApplicationsUpdateStageBody }) =>
      updateApplicationsStage(applicationId, body),
    onSuccess: async (_, { applicationId, body }) => {
      await queryClient.invalidateQueries({ queryKey: jobsBoardQuery(jobId).queryKey })
      await queryClient.invalidateQueries({ queryKey: applicationsLastActivitiesQuery(applicationId).queryKey })

      queryClient.setQueryData(applicationsJobStagesQuery(applicationId).queryKey, (oldData) => {
        if (!oldData) return oldData

        return {
          ...oldData,
          currentStage: body.toStageId,
        }
      })
    },
  })
}

// PUT /applications/:applicationId/applicant-info
export function useUpdateApplicationsApplicant(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body }: { body: UpdateApplicationsApplicantBody }) =>
      updateApplicationsApplicant(applicationId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsApplicantQuery(applicationId).queryKey })
      await queryClient.invalidateQueries({ queryKey: jobsBoardQuery(applicationId).queryKey })
    },
  })
}

// POST /applications
export function useCreateApplication() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: CreateApplicationBody) => createApplication(body),
    onSuccess: async (_, { jobId }) => {
      await queryClient.invalidateQueries({ queryKey: jobsBoardQuery(jobId).queryKey })
    },
  })
}

// POST /applications/:applicationId/comments
export function useCreateApplicationsComment(applicationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body }: { body: CreateApplicationCommentBody }) => createApplicationsComment(applicationId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: applicationsLastActivitiesQuery(applicationId).queryKey })
    },
  })
}

// PUT /applications/:applicationId/refuse-application
export function useUpdateApplicationsRefuseApplication({
  applicationId,
  jobId,
}: {
  applicationId: string
  jobId: string
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ body }: { body: UpdateRefuseApplicationBody }) =>
      updateApplicationsRefuseApplication(applicationId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: jobsBoardQuery(jobId).queryKey })
    },
  })
}
