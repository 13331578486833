import { queryOptions, useMutation, useQueryClient } from '@tanstack/react-query'

import { createOrganization, getOrganizations, updateOrganization } from '@/api/organizations'
import { OrganizationCreateOrUpdateBody } from '@/types/organization'

/**
 * Query options
 */

// GET /organizations
export const organizationsQuery = queryOptions({
  queryFn: () => getOrganizations(),
  queryKey: ['organizations'],
})

// GET /organizations?view=minimal
export const organizationsMinimalQuery = queryOptions({
  queryFn: () => getOrganizations('minimal'),
  queryKey: ['organizations', 'minimal'],
})

/**
 * Mutations
 */

// POST /organizations
export const useCreateOrganization = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: OrganizationCreateOrUpdateBody) => createOrganization(body),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationsQuery.queryKey })
    },
  })
}

// PUT /organizations/:id
export const useUpdateOrganization = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: OrganizationCreateOrUpdateBody) => updateOrganization(id, body),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: organizationsQuery.queryKey })
    },
  })
}
