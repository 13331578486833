import { AxiosRequestConfig } from 'axios'

import apiClient from '@/lib/apiClient'

interface AxiosDeleteConfig extends AxiosRequestConfig {
  customErrorHandling: boolean
}

/**
 * Sends a DELETE request to the specified URL using axiosInstance.
 * @template TResponse The expected response type.
 * @param {string} url The URL to send the request to.
 * @param {AxiosRequestConfig} [config] The optional request configuration.
 * @returns {Promise<TResponse>} A promise that resolves with the response data.
 * @throws {AxiosError} If the request fails.
 */
export async function axiosDelete<TResponse>(url: string, config?: AxiosDeleteConfig): Promise<TResponse> {
  // try {
  const response = await apiClient.delete<TResponse>(url, config)
  return response.data
}
