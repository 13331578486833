import { ReactNode, Suspense } from 'react'

import { Loader2 } from 'lucide-react'

interface LoadingWrapperProps {
  children: ReactNode
  isLoading: boolean
  skeleton?: ReactNode
}

export function LoadingWrapper({ children, isLoading, skeleton }: LoadingWrapperProps) {
  const spinner = skeleton ?? (
    <div className="flex justify-center p-2">
      <Loader2 className="animate-spin text-muted-foreground" />
    </div>
  )
  return <Suspense fallback={spinner}>{isLoading ? spinner : children}</Suspense>
}
