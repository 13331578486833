import { Link } from '@tanstack/react-router'

import { Navbar } from './navbar'
import { UserButton } from './user-button'

export function Header() {
  return (
    <header className="fixed top-0 z-30 flex h-16 w-full items-center gap-x-12 bg-primary px-8">
      <Link className="text-3xl font-semibold uppercase text-white" title="Retour à l'accueil" to="/">
        ATS
      </Link>
      <Navbar />
      <div className="ml-auto">
        <UserButton />
      </div>
    </header>
  )
}
