import { Star } from 'lucide-react'

import { cn } from '@/lib/utils'

interface ScoringProps {
  max?: number
  value: number
}

export function Scoring({ max = 5, value }: ScoringProps) {
  const stars = []

  for (let i = 0; i < max; i++) {
    stars.push(
      <Star
        className={cn('fill-primary text-primary opacity-10', i < Math.round(value / 20) && 'opacity-100')}
        key={i}
        size={16}
      />,
    )
  }

  return <div className="flex gap-x-0.5">{stars}</div>
}
