import { Link, createFileRoute } from '@tanstack/react-router'

import { Text } from '@/components/ui/text'
import { Title } from '@/components/ui/title'
import { LoginForm } from '@/features/auth/login/login-form'

export const Route = createFileRoute('/auth/login')({
  component: AuthLogin,
})

function AuthLogin() {
  return (
    <div className="flex w-96 flex-col gap-y-4">
      <div className="mb-4 flex flex-col gap-y-4 text-center">
        <Title align="center" level={3}>
          Login to your ATS account
        </Title>
        <Text weight="light">Candidates and Positions database</Text>
      </div>
      <div className="rounded-md bg-white p-6 shadow-sm">
        <LoginForm />
      </div>
      <Link className="text-center" to="/auth/forgot-password">
        <Text size="sm">Forgot password ?</Text>
      </Link>
    </div>
  )
}
