import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from '@tanstack/react-router'
import { Send } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useCreateApplicationsComment } from '@/hooks/use-applications-queries'
import { PostCommentValues, postCommentSchema } from '@/schemas/applications/comments/create'

export function ActivityForm() {
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const form = useForm<PostCommentValues>({
    defaultValues: {
      comment: '',
    },
    resolver: zodResolver(postCommentSchema),
  })

  const { isPending, mutate: createComment } = useCreateApplicationsComment(applicationId)

  const { control, handleSubmit, reset } = form

  const onSubmit = (values: PostCommentValues) => {
    createComment(
      { body: values },
      {
        onSuccess: () => {
          reset()
          toast.success('Comment added successfully.')
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="mt-2 flex flex-col gap-y-2" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.TEXTAREA}
          name="comment"
          placeholder="Add a comment..."
        />

        <Button className="ml-auto" isLoading={isPending} size="icon" type="submit">
          <Send size={16} />
        </Button>
      </form>
    </Form>
  )
}
