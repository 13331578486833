import { DialogTrigger } from '@radix-ui/react-dialog'
import { MailIcon } from 'lucide-react'

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Text } from '@/components/ui/text'
import { EmailSentContent } from '@/types/application/activities'

export function EmailSent({ body, message, subject }: EmailSentContent) {
  return (
    <Dialog>
      <DialogTrigger className="flex gap-x-1">
        <MailIcon className="shrink-0 text-primary" size={16} />
        <Text size="xs">See email</Text>
      </DialogTrigger>
      <DialogContent className="max-h-[calc(100vh-4rem)] max-w-4xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="font-normal" dangerouslySetInnerHTML={{ __html: message }} />
        </DialogHeader>
        <div className="space-y-8">
          <div>
            <Text weight="semibold">Subject</Text>
            <Text size="sm">{subject}</Text>
          </div>
          <div>
            <Text weight="semibold">Body</Text>
            <div className="space-y-6 text-sm" dangerouslySetInnerHTML={{ __html: body }}></div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
