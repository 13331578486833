import { useSuspenseQuery } from '@tanstack/react-query'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { FileBarChart, FileOutput, FileText, FileType, HeartHandshake, LockKeyholeIcon } from 'lucide-react'
import { toast } from 'sonner'

import { Sidebar } from '@/components/sidebar'
import { Button } from '@/components/ui/button'
import { usePublishJob } from '@/queries/use-jobs-mutations'
import { jobsStatusQuery } from '@/queries/use-jobs-queries'

const SIDEBAR = [
  {
    exact: true,
    icon: FileType,
    label: 'Job informations',
    to: '/jobs/$jobId/edit',
  },
  {
    icon: FileText,
    label: 'Job description',
    to: '/jobs/$jobId/edit/description',
  },
  {
    icon: FileBarChart,
    label: 'Stages',
    to: '/jobs/$jobId/edit/stages',
  },
  {
    icon: HeartHandshake,
    label: 'Recruiters',
    to: '/jobs/$jobId/edit/recruiters',
  },
  {
    icon: LockKeyholeIcon,
    label: 'Internal informations',
    to: '/jobs/$jobId/edit/internal-informations',
  },
]

export const Route = createFileRoute('/_authenticated/jobs/$jobId/edit')({
  component: JobEdit,
  loader: ({ context: { queryClient }, params: { jobId } }) => queryClient.ensureQueryData(jobsStatusQuery(jobId)),
})

function JobEdit() {
  const { jobId } = Route.useParams()
  const {
    data: { status },
  } = useSuspenseQuery(jobsStatusQuery(jobId))

  const { mutate: publishJob } = usePublishJob()

  const handlePublish = () => {
    publishJob(
      { jobId },
      {
        onSuccess: () => {
          toast.success('Job successfully published.')
        },
      },
    )
  }

  return (
    <div className="flex">
      <Sidebar backLink={{ label: 'Back to board', params: { jobId }, to: '/jobs/$jobId' }} links={SIDEBAR}>
        {status === 'DRAFT' && (
          <div className="mt-2 px-8">
            <Button fullwidth onClick={handlePublish} variant="secondary">
              <FileOutput size={16} />
              Publish
            </Button>
          </div>
        )}
      </Sidebar>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  )
}
