import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { SingleValue } from 'react-select'

import { Select } from '@/components/select'
import { jobsStagesQuery } from '@/queries/use-jobs-queries'

import { isDroppable } from './utils'

interface StageSelectProps {
  onChange: (
    newValue: SingleValue<{
      label: string
      value: string
    }>,
  ) => void
  placeholder?: string
}

export function StageSelect({ onChange, placeholder = 'Select stage' }: StageSelectProps) {
  const { jobId } = useParams({ from: '/_authenticated/jobs/$jobId' })
  const { data, isLoading } = useQuery(jobsStagesQuery(jobId))

  const options = data?.stages.map(({ id, name, order }) => ({
    isDisabled: !isDroppable(order),
    label: name,
    value: id,
  }))

  return (
    <Select
      isLoading={isLoading}
      menuPlacement="top"
      menuPosition="absolute"
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />
  )
}
