import { createFileRoute, Link } from '@tanstack/react-router'

import { Text } from '@/components/ui/text'
import { Title } from '@/components/ui/title'
import { ForgotPasswordForm } from '@/features/auth/forgot-password/forgot-password-form'

export const Route = createFileRoute('/auth/forgot-password')({
  component: ForgotPassword,
})

function ForgotPassword() {
  return (
    <div className="flex w-96 flex-col gap-y-4">
      <div className="mb-4 flex flex-col gap-y-4 text-center">
        <Title align="center" level={3}>
          Forgot password
        </Title>
        <Text weight="light">We will send you an email with instructions to reset your password.</Text>
      </div>
      <div className="rounded-md bg-white p-6 shadow-sm">
        <ForgotPasswordForm />
      </div>
      <Link className="text-center" to="/auth/login">
        <Text size="sm">Return to login</Text>
      </Link>
    </div>
  )
}
