import { ReactNode } from 'react'

import { cn } from '@/lib/utils'

interface PageMenuProps {
  children: ReactNode
  className?: string
}

export function PageMenu({ children, className }: PageMenuProps) {
  return (
    <div className={cn('flex h-16 shrink-0 items-center gap-x-4 border-b bg-white px-8', className)}>{children}</div>
  )
}
