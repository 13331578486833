import axios, { AxiosError } from 'axios'

import { refreshToken } from '@/api/auth'
import { User } from '@/providers/auth-provider'

let retry = false

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  headers: {
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('user')

    if (user) {
      const parsedUser = JSON.parse(user) as User

      config.headers.Authorization = `Bearer ${parsedUser.accessToken}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

apiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<{ message: string; statusCode: number; title?: string }>) => {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
    }

    const user = localStorage.getItem('user')

    const originalRequest = error.config

    if (error.response?.status === 401 && !retry && user && originalRequest) {
      try {
        retry = true
        const parsedUser = JSON.parse(user) as User
        const response = await refreshToken()
        localStorage.setItem('user', JSON.stringify({ ...parsedUser, accessToken: response.accessToken }))
        originalRequest.headers.Authorization = `Bearer ${response.accessToken}`

        return apiClient(originalRequest)
      } catch (error) {
        localStorage.removeItem('user')
        return Promise.reject(error)
      } finally {
        retry = false
      }
    }

    return Promise.reject(error)
  },
)

export default apiClient
