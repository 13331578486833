import { JobLocation } from '@/types/job'

interface FullNameProps {
  firstname: string
  lastname: string
}

export const getFullName = <T extends FullNameProps>({ firstname, lastname }: T): string => {
  return `${firstname} ${lastname}`
}

export const getFullNameInitial = <T extends FullNameProps>({ firstname, lastname }: T): string => {
  const firstnameInitials = firstname
    .split(' ')
    .map((f) => f[0])
    .join('')

  const lastnameInitials = lastname
    .split(' ')
    .map((l) => l[0])
    .join('')

  return `${firstnameInitials}${lastnameInitials}`
}

interface JobNameProps {
  currentCompany?: string
  currentJob?: string
}

export const getJobName = <T extends JobNameProps>({ currentCompany, currentJob }: T): string => {
  if (!currentJob && !currentCompany) return '-'
  return `${currentJob || '-'} ${currentCompany ? `@ ${currentCompany}` : ''}`
}

export function getUniqueLocations(jobLocations: JobLocation[]): string[] {
  const locations = jobLocations.map((location) => location.location.name)
  return [...new Set(locations)]
}

export function formatJobLocations(jobLocations: JobLocation[]): string {
  return getUniqueLocations(jobLocations).join(', ')
}
