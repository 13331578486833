import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import { Text } from '@/components/ui/text'
import { Title } from '@/components/ui/title'
import { CreatePasswordForm } from '@/features/auth/create-password/create-password-form'

export const Route = createFileRoute('/auth/create-password')({
  beforeLoad: ({ context, search: { email, token } }) => {
    if ((!email || !token) && !context.auth.isAuthenticated()) {
      throw redirect({
        to: '/auth/login',
      })
    }
  },
  component: ResetPassword,
  validateSearch: z.object({
    email: z.string().min(1),
    token: z.string().min(1),
  }),
})

function ResetPassword() {
  return (
    <div className="flex w-96 flex-col gap-y-4">
      <div className="mb-4 flex flex-col gap-y-4 text-center">
        <Title align="center" level={3}>
          Create your password
        </Title>
        <Text weight="light">Enter and confirm your new password to complete the create process.</Text>
      </div>
      <div className="rounded-md bg-white p-6 shadow-sm">
        <CreatePasswordForm />
      </div>
    </div>
  )
}
