import { Text } from '@/components/ui/text'
import { ActivitiesApplicationItem, ActivityType } from '@/types/application/activities'
import { getDistanceToNowStrict } from '@/utils/date'

import { Comment } from './activity/comment'
import { EmailSent } from './activity/email-sent'
import { InterviewCreated } from './activity/interview-created'
import { InterviewInvite } from './activity/interview-invite'
import { InterviewNote } from './activity/interview-note'
import { TaskDone } from './activity/task-done'
import { TaskToDo } from './activity/task-to-do'
import { ActivityAvatar } from './activity-avatar'

interface ActivityProps {
  item: ActivitiesApplicationItem
}

export function Activity({ item }: ActivityProps) {
  const renderContent = () => {
    switch (item.type) {
      case ActivityType.COMMENT:
        if (item.content && 'commentId' in item.content && 'comment' in item.content) {
          return <Comment {...item.content} />
        }
        return null

      case ActivityType.INTERVIEW_CREATED:
        if (item.content && 'scheduledAt' in item.content) {
          return <InterviewCreated {...item.content} />
        }
        return null

      case ActivityType.INTERVIEW_INVITE:
        if (item.content && 'emailId' in item.content) {
          return <InterviewInvite {...item.content} />
        }
        return null

      case ActivityType.INTERVIEW_NOTE:
        if (item.content && 'note' in item.content) {
          return <InterviewNote {...item.content} />
        }
        return null

      case ActivityType.TASK_DONE:
        if (item.content && 'taskId' in item.content && 'taskContent' in item.content) {
          return <TaskDone {...item.content} />
        }
        return null

      case ActivityType.TASK_TO_DO:
        if (
          item.content &&
          'dueAt' in item.content &&
          'taskId' in item.content &&
          'status' in item.content &&
          'taskContent' in item.content
        ) {
          return <TaskToDo {...item.content} />
        }
        return null

      case ActivityType.EMAIL_SENT:
        if (item.content && 'body' in item.content && 'subject' in item.content) {
          return <EmailSent {...item.content} message={item.message} />
        }
        return null

      case ActivityType.APPLICANT_ADDED:
      case ActivityType.HISTORY:
      case ActivityType.INTERVIEW:
      case ActivityType.INTERVIEW_PICK_SLOT:
      case ActivityType.JOB_CLOSED:
      case ActivityType.JOB_FILLED:
      case ActivityType.QUALIFICATION:
      case ActivityType.STAGE_UPDATED:
      default:
        return null
    }
  }

  return (
    <div className="flex gap-x-2">
      <ActivityAvatar performerType={item.performerType} />
      <div className="flex flex-1 justify-between gap-x-2">
        <div className="flex flex-col gap-y-1">
          <p className="text-sm" dangerouslySetInnerHTML={{ __html: item.message }} />
          {renderContent()}
        </div>
        <div className="shrink-0">
          <Text size="xs" variant="muted">
            {getDistanceToNowStrict(item.createdAt)}
          </Text>
        </div>
      </div>
    </div>
  )
}
