import { RouterProvider } from '@tanstack/react-router'

import { useAuth } from './hooks/use-auth'
import { router } from './router'

export default function App() {
  const auth = useAuth()

  return <RouterProvider context={{ auth }} router={router} />
}
