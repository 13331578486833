import { useState } from 'react'

import { useParams } from '@tanstack/react-router'
import { Download, Save, SquarePen } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { FilePreview } from '@/components/file-preview'
import { ConfigSelect } from '@/components/form/config-select'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Text } from '@/components/ui/text'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import { useUpdateApplicationsDocuments } from '@/queries/use-applications-mutations'
import { ApplicationsDocument } from '@/types/application/documents'

import { DocumentModal } from './document-modal'

interface DocumentProps extends ApplicationsDocument {}

export function Document({ id, mimeType, name, type, url }: DocumentProps) {
  const [open, onOpenChange] = useState(false)
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const { mutate: updateApplicationsDocuments } = useUpdateApplicationsDocuments(applicationId)
  const form = useForm({ defaultValues: { name } })

  const { control, handleSubmit } = form

  const onSubmit = (values: { name: string }) => {
    updateApplicationsDocuments(
      { body: { name: values.name }, documentId: id },
      {
        onSuccess: () => {
          onOpenChange(false)
          toast.success('Document name updated.')
        },
      },
    )
  }

  const onChangeType = (value: string) => {
    if (value === type) return

    updateApplicationsDocuments(
      { body: { type: value }, documentId: id },
      {
        onSuccess: () => {
          toast.success('Document type updated.')
        },
      },
    )
  }

  return (
    <div className="flex w-full flex-col rounded border">
      <div className="relative aspect-video overflow-hidden border-b">
        <FilePreview mimeType={mimeType} name={name} url={url} />
        <div className="absolute bottom-2 left-2 w-1/2">
          <ConfigSelect onChange={onChangeType} type="assetTypes" value={type} />
        </div>
      </div>

      <div className="flex w-full items-center justify-between gap-x-2 p-2">
        <Text size="sm" truncate weight="medium">
          {name}
        </Text>
        <div className="flex items-center gap-x-2">
          <DocumentModal mimeType={mimeType} name={name} url={url} />
          <Tooltip>
            <TooltipTrigger asChild>
              <a href={url} rel="noreferrer" target="_blank">
                <Download className="text-muted-foreground" size={16} />
              </a>
            </TooltipTrigger>
            <TooltipContent>Dowload document</TooltipContent>
          </Tooltip>
          <Popover onOpenChange={onOpenChange} open={open}>
            <PopoverTrigger>
              <Tooltip>
                <TooltipTrigger asChild>
                  <SquarePen className="text-muted-foreground" size={16} />
                </TooltipTrigger>
                <TooltipContent>Edit document name</TooltipContent>
              </Tooltip>
            </PopoverTrigger>
            <PopoverContent side="top">
              <Form {...form}>
                <form className="flex w-full gap-x-2" onSubmit={handleSubmit(onSubmit)}>
                  <CustomFormField
                    control={control}
                    fieldType={FormFieldType.INPUT}
                    name="name"
                    placeholder="Document name"
                  />
                  <Button size="icon" type="submit">
                    <Save size={16} />
                  </Button>
                </form>
              </Form>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  )
}
