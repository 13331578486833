import { queryOptions } from '@tanstack/react-query'

import { getLocationsSearch } from '@/api/locations'

/**
 * Query options
 */

// GET /locations/search
export const locationsSearchQuery = (query: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => getLocationsSearch(query),
    queryKey: ['locations', query],
  })
