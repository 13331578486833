import { EuroIcon } from 'lucide-react'

import { Text } from '@/components/ui/text'

function displaySalary(salary?: number) {
  if (!salary) return '-'
  return salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export default function ExpectedSalary({ value }: { value?: number }) {
  return (
    <div className="flex items-center gap-x-1">
      <EuroIcon className="text-muted-foreground" size={16} />
      <Text size="xs">{displaySalary(value)}</Text>
    </div>
  )
}
