import * as React from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const labelVariants = cva(
  'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    defaultVariants: {
      hasError: false,
    },
    variants: {
      hasError: {
        true: 'text-destructive',
      },
    },
  },
)

interface LabelProps extends VariantProps<typeof labelVariants> {
  hasError?: boolean
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & LabelProps
>(({ className, hasError, ...props }, ref) => (
  <LabelPrimitive.Root className={cn(labelVariants({ hasError }), className)} ref={ref} {...props} />
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
