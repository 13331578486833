import { Select } from '../select'

const DELAY_OPTIONS = [
  {
    label: 'Same day',
    value: 0,
  },
  {
    label: '1 day',
    value: 1,
  },
  {
    label: '2 days',
    value: 2,
  },
  {
    label: '3 days',
    value: 3,
  },
  {
    label: '4 days',
    value: 4,
  },
  {
    label: '5 days',
    value: 5,
  },
  {
    label: '6 days',
    value: 6,
  },
  {
    label: '7 days',
    value: 7,
  },
]

interface DelaySelectProps {
  value?: number | null
  onChange: (value: number) => void
}

export function DelaySelect({ onChange, value }: DelaySelectProps) {
  return (
    <Select
      onChange={(value) => value && onChange(value?.value)}
      options={DELAY_OPTIONS}
      placeholder="Select delay..."
      value={DELAY_OPTIONS.find((opt) => opt.value === value)}
    />
  )
}
