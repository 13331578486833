import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { ConfigSelect } from '@/components/form/config-select'
import { DatetimepickerInput } from '@/components/form/datetimepicker-input'
import { DurationSelect } from '@/components/form/duration-select'
import { RecruiterSelect } from '@/components/form/recruiter-select'
import { Button } from '@/components/ui/button'
import { Form, FormControl } from '@/components/ui/form'
import { SheetClose, SheetFooter } from '@/components/ui/sheet'
import { useCreateApplicationsInterviews } from '@/queries/use-applications-mutations'
import { PostInterviewValues, postInterviewSchema } from '@/schemas/interviews/create'
import { isBeforeToday } from '@/utils/date'

interface CreateFormProps {
  onClose: () => void
}

export function CreateForm({ onClose }: CreateFormProps) {
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })

  const form = useForm<PostInterviewValues>({
    defaultValues: {
      duration: undefined,
      location: '',
      participant: '',
      scheduledAt: undefined,
      timeStatus: '',
      timeZone: undefined,
      type: '',
      videoService: '',
    },
    resolver: zodResolver(postInterviewSchema),
  })

  const { isPending, mutate: createApplicationsInterview } = useCreateApplicationsInterviews(applicationId)

  const { control, handleSubmit, watch } = form

  const onSubmit = (values: PostInterviewValues) => {
    let data = { ...values }

    if (values.timeStatus === 'CALENDLY') {
      data = {
        ...data,
        scheduledAt: undefined,
      }
    }

    if (values.timeStatus === 'SCHEDULED' || values.timeStatus === 'DIRECT_CALL') {
      data = {
        ...data,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    }

    if (values.type === 'VISIO') {
      data = {
        ...data,
        location: undefined,
      }
    }

    if (values.type === 'PHONE') {
      data = {
        ...data,
        location: undefined,
        videoService: undefined,
      }
    }

    if (values.type === 'PHYSICAL') {
      data = {
        ...data,
        videoService: undefined,
      }
    }

    createApplicationsInterview(
      { body: data },
      {
        onSuccess: () => {
          onClose()
          toast.success('Interview created')
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="mt-8 flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Participant"
          name="participant"
          renderSkeleton={(field) => (
            <FormControl>
              <RecruiterSelect onChange={field.onChange} placeholder="Select participant..." value={field.value} />
            </FormControl>
          )}
        />
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Time"
          name="timeStatus"
          renderSkeleton={(field) => (
            <FormControl>
              <ConfigSelect onChange={field.onChange} type="interviewTimeStatuses" value={field.value} />
            </FormControl>
          )}
        />
        {watch('timeStatus') === 'SCHEDULED' && (
          <CustomFormField
            control={control}
            fieldType={FormFieldType.SKELETON}
            label="Scheduled at"
            name="scheduledAt"
            renderSkeleton={(field) => (
              <FormControl>
                <DatetimepickerInput disabled={isBeforeToday} onChange={field.onChange} value={field.value} />
              </FormControl>
            )}
          />
        )}
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Type"
          name="type"
          renderSkeleton={(field) => (
            <FormControl>
              <ConfigSelect onChange={field.onChange} type="interviewTypes" value={field.value} />
            </FormControl>
          )}
        />
        {watch('type') === 'PHYSICAL' && (
          <CustomFormField
            control={control}
            fieldType={FormFieldType.INPUT}
            label="Location"
            name="location"
            placeholder="Location"
          />
        )}
        {watch('type') === 'VISIO' && (
          <CustomFormField
            control={control}
            fieldType={FormFieldType.SKELETON}
            label="Video service"
            name="videoService"
            renderSkeleton={(field) => (
              <FormControl>
                <ConfigSelect onChange={field.onChange} type="videoServices" value={field.value} />
              </FormControl>
            )}
          />
        )}
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Duration"
          name="duration"
          renderSkeleton={(field) => (
            <FormControl>
              <DurationSelect onChange={field.onChange} value={field.value} />
            </FormControl>
          )}
        />
        <SheetFooter className="mt-8">
          <SheetClose asChild>
            <Button disabled={isPending} variant="outline">
              Cancel
            </Button>
          </SheetClose>
          <Button isLoading={isPending} type="submit">
            Create
          </Button>
        </SheetFooter>
      </form>
    </Form>
  )
}
