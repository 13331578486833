import { AxiosRequestConfig } from 'axios'

import apiClient from '@/lib/apiClient'

/**
 * Makes a GET request to the specified URL and returns the response data.
 * @param url - The URL to make the GET request to.
 * @param config - Optional Axios request configuration.
 * @returns A Promise that resolves to the response data.
 * @throws An error if the request fails.
 */
export async function get<TResponse>(url: string, config?: AxiosRequestConfig): Promise<TResponse> {
  const response = await apiClient.get<TResponse>(url, config)
  return response.data
}
