import { Link, LinkProps } from '@tanstack/react-router'
import { ChevronLeft, LucideIcon } from 'lucide-react'

import { cn } from '@/lib/utils'

interface SidebarProps {
  links: {
    label: string
    to: string
    icon: LucideIcon
    exact?: boolean
    disabled?: boolean
  }[]
  children?: React.ReactNode
  backLink?: {
    to: LinkProps['to']
    label: string
    params: LinkProps['params']
  }
}

export function Sidebar({ backLink, children, links }: SidebarProps) {
  return (
    <aside className="sticky top-16 flex h-[calc(100vh-4rem)] w-[256px] flex-none flex-col gap-y-4 border-r bg-white py-6">
      {backLink && (
        <Link
          className="flex items-center gap-x-4 self-start px-8 py-2 text-sm font-medium text-foreground/40 transition-colors hover:text-primary"
          params={backLink.params}
          to={backLink.to}
        >
          <ChevronLeft size={16} />
          {backLink.label}
        </Link>
      )}
      {links.map(({ disabled, exact, icon: Icon, label, to }) => (
        <Link
          activeOptions={{ exact }}
          activeProps={{
            className: 'text-primary after:opacity-100',
          }}
          className={cn(
            "relative flex items-center gap-x-4 px-8 py-2 text-sm font-medium transition after:absolute after:inset-y-0 after:left-0 after:w-1 after:bg-primary after:opacity-0 after:content-['']",
            disabled && 'opacity-50 cursor-not-allowed',
            !disabled && 'hover:text-primary',
          )}
          disabled={disabled}
          key={to}
          to={to}
        >
          <Icon size={16} />
          {label}
        </Link>
      ))}
      {children}
    </aside>
  )
}
