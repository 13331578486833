import { PageHeader } from '../page-header'
import { Skeleton } from '../ui/skeleton'

interface PageHeaderSkeleton {
  title: string
  cta?: boolean
}

export const PageHeaderSkeleton = ({ cta, title }: PageHeaderSkeleton) => {
  return <PageHeader cta={cta && <Skeleton className="h-9 w-32" />} title={title} />
}
