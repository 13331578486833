import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { CalendarIcon } from 'lucide-react'
import { Control, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { Value as PhoneNumberValue } from 'react-phone-number-input'

import { cn } from '@/lib/utils'

import { Button } from './ui/button'
import { Calendar } from './ui/calendar'
import { Checkbox } from './ui/checkbox'
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from './ui/form'
import { Input } from './ui/input'
import { PhoneNumberInput } from './ui/phone-number'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'
import { Switch } from './ui/switch'
import { Textarea } from './ui/textarea'

export enum FormFieldType {
  CHECKBOX = 'checkbox',
  DATE_PICKER = 'datePicker',
  INPUT = 'input',
  NUMBER_INPUT = 'numberInput',
  PHONE_INPUT = 'phoneInput',
  SELECT = 'select',
  SKELETON = 'skeleton',
  SWITCH = 'switch',
  TEXTAREA = 'textarea',
}

interface CustomFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  children?: React.ReactNode
  control: Control<TFieldValues>
  dateFormat?: string
  disabled?: boolean
  fieldType: FormFieldType
  label?: React.ReactNode
  name: TName
  description?: string
  placeholder?: string
  showTimeSelect?: boolean
  type?: 'text' | 'email' | 'password' | 'hidden'
  renderSkeleton?: (field: ControllerRenderProps<TFieldValues, TName>) => React.ReactNode
}

function RenderField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field,
  props,
}: {
  field: ControllerRenderProps<TFieldValues, TName>
  props: CustomFormFieldProps<TFieldValues, TName>
}) {
  const { disabled, fieldType, placeholder, renderSkeleton, type = 'text' } = props

  switch (fieldType) {
    case FormFieldType.DATE_PICKER:
      return (
        <Popover>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                variant="outline"
              >
                {field.value ? format(field.value, 'P', { locale: fr }) : <span>{placeholder ?? 'Pick a date'}</span>}
                <CalendarIcon className="ml-auto size-4 opacity-50" />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContent align="start" className="w-auto p-0">
            <Calendar
              autoFocus
              defaultMonth={field.value}
              disabled={disabled}
              mode="single"
              onSelect={field.onChange}
              selected={field.value}
            />
          </PopoverContent>
        </Popover>
      )
    case FormFieldType.INPUT:
      return (
        <FormControl>
          <Input type={type} {...field} disabled={disabled} placeholder={placeholder} />
        </FormControl>
      )
    case FormFieldType.NUMBER_INPUT:
      return (
        <FormControl>
          <Input
            {...field}
            disabled={disabled}
            min={0}
            onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : 0)}
            placeholder={placeholder}
            type="number"
          />
        </FormControl>
      )
    case FormFieldType.PHONE_INPUT:
      return (
        <FormControl>
          <PhoneNumberInput onChange={field.onChange} value={field.value as PhoneNumberValue} />
        </FormControl>
      )
    case FormFieldType.SKELETON:
      return renderSkeleton ? renderSkeleton(field) : null
    case FormFieldType.SWITCH:
      return (
        <FormControl>
          <Switch checked={field.value} disabled={disabled} onCheckedChange={field.onChange} />
        </FormControl>
      )
    case FormFieldType.TEXTAREA:
      return (
        <FormControl>
          <Textarea disabled={disabled} {...field} className="min-h-32" placeholder={placeholder} />
        </FormControl>
      )
    case FormFieldType.CHECKBOX:
      return (
        <FormControl>
          <Checkbox checked={field.value} disabled={disabled} onCheckedChange={field.onChange} />
        </FormControl>
      )
    default:
      break
  }
}

export function CustomFormField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: CustomFormFieldProps<TFieldValues, TName>) {
  const { control, description, label, name } = props

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          className={cn('flex-1', props.fieldType === FormFieldType.CHECKBOX && 'flex-row-reverse justify-end gap-x-2')}
        >
          {label && <FormLabel>{label}</FormLabel>}
          {description && <FormDescription>{description}</FormDescription>}
          <RenderField field={field} props={props} />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
