import { BriefcaseBusinessIcon } from 'lucide-react'

import { Text } from '@/components/ui/text'

function displayYearOfExperience(value?: number) {
  if (!value) return '-'
  return value === 1 ? `${value} year` : `${value} years`
}

export default function YearOfExperience({ value }: { value?: number }) {
  return (
    <div className="flex items-center gap-x-1">
      <BriefcaseBusinessIcon className="text-muted-foreground" size={16} />
      <Text size="xs">{displayYearOfExperience(value)}</Text>
    </div>
  )
}
