import { z } from 'zod'

export const putRefuseApplicationSchema = z
  .object({
    emailSendingDelay: z.number().optional(),
    emailTemplateId: z.string().optional(),
    hasEmail: z.boolean(),
    refusalReasonId: z.string(),
  })
  .refine((data) => !(data.hasEmail && data.emailSendingDelay === null), {
    message: 'Delay is required.',
    path: ['emailSendingDelay'],
  })
  .refine((data) => !(data.hasEmail && !data.emailTemplateId), {
    message: 'Email template is required.',
    path: ['emailTemplateId'],
  })

export type PutRefuseApplicationValues = z.infer<typeof putRefuseApplicationSchema>
