import { forwardRef } from 'react'

import { LinkedInLogoIcon } from '@radix-ui/react-icons'
import { useNavigate, useParams } from '@tanstack/react-router'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { Skeleton } from '@/components/ui/skeleton'
import { Text } from '@/components/ui/text'
import { useBulkActions } from '@/hooks/use-bulk-actions'
import { cn } from '@/lib/utils'
import { JobBoardApplicationWithStageId } from '@/types/application/job-board'
import { getDistanceToNowStrict } from '@/utils/date'
import { getFullName, getFullNameInitial } from '@/utils/string'

import { CardFooter } from './card-footer'
import { Availability } from './components/availibity'
import ExpectedSalary from './components/expected-salary'
import YearOfExperience from './components/year-of-experience'
import { isBulkableOrDraggable } from './utils'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  application: JobBoardApplicationWithStageId
  color: string
  overlay?: boolean
}

const Card = forwardRef<HTMLDivElement, CardProps>(({ application, color, overlay, style, ...props }, ref) => {
  const { isOpen, selected, setSelected } = useBulkActions()
  const { jobId } = useParams({ from: '/_authenticated/jobs/$jobId' })
  const navigate = useNavigate({ from: '/jobs/$jobId' })

  const isInteractive = isBulkableOrDraggable(application.stageName)

  return (
    <div
      className={cn(
        'relative overflow-hidden rounded border-l-4 bg-white shadow-sm transition-shadow shrink-0 w-full',
        overlay && 'shadow-2xl cursor-grabbing',
        isInteractive && 'active:cursor-grabbing cursor-grab',
      )}
      ref={ref}
      style={{ borderLeftColor: color, ...style }}
      {...props}
      role="button"
    >
      <div
        className="flex gap-x-2 p-3"
        onClick={async () => {
          if (isOpen) return
          await navigate({
            params: { applicationId: application.id, jobId },
            search: { view: 'application' },

            to: '/jobs/$jobId/applications/$applicationId',
          })
        }}
      >
        <div className="flex gap-x-2">
          <Avatar>
            <AvatarFallback>{getFullNameInitial(application.applicant)}</AvatarFallback>
          </Avatar>
          <div className="flex-1">
            <div className="flex items-center gap-x-1">
              <Text size="sm" weight="medium">
                {getFullName(application.applicant)}
              </Text>
              {application.applicant.linkedinUrl && (
                <a
                  href={application.applicant.linkedinUrl}
                  onClick={(e) => e.stopPropagation()}
                  rel="noreferrer"
                  target="_blank"
                  title="See LinkedIn profile"
                >
                  <LinkedInLogoIcon className="text-linkedin" />
                </a>
              )}
            </div>
            <Text size="sm" weight="light">
              {application.applicant.currentJob}{' '}
              {application.applicant.currentCompany && `@${application.applicant.currentCompany}`}
            </Text>
          </div>
        </div>
        <div
          className={cn(
            'ml-auto shrink-0 transition-transform ease-in-out',
            isOpen && isInteractive && '-translate-x-6',
          )}
        >
          <Text size="xs">{getDistanceToNowStrict(application.createdAt)}</Text>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-x-2 px-4 pb-4">
        <YearOfExperience value={application.applicant.yearOfExperience} />
        <Availability value={application.applicant.availability} />
        <ExpectedSalary value={application.applicant.expectedSalary} />
      </div>

      <CardFooter application={application} />

      {isInteractive && (
        <Label
          className={cn(
            'absolute inset-0 pointer-events-none p-3 opacity-0 transition-opacity bg-background/50 border border-transparent rounded-r text-right',
            isOpen && 'cursor-pointer opacity-100 pointer-events-auto',
            selected.find((id) => id === `${application.id}`) && 'bg-primary/10 border-primary',
          )}
          htmlFor={`${application.id}`}
        >
          <Checkbox
            checked={selected.includes(`${application.id}`)}
            className="pointer-events-none"
            id={`${application.id}`}
            onCheckedChange={(e) =>
              e
                ? setSelected([...selected, `${application.id}`])
                : setSelected(selected.filter((sel) => sel !== `${application.id}`))
            }
          />
        </Label>
      )}
    </div>
  )
})

Card.displayName = 'Card'

function CardSkeleton() {
  return <Skeleton className="h-24 w-full" />
}

export { Card, CardSkeleton }
