import { get } from '@/services/get'
import { EmailTemplate, EmailTemplates } from '@/types/emailTemplate'

export function getEmailTemplates() {
  return get<EmailTemplates[]>('/email-templates')
}

export function getEmailTemplate(emailTemplateId?: string) {
  return get<EmailTemplate>(`/email-templates/${emailTemplateId}`)
}

export function getEmailTemplatesByOrganization(organizationId: string) {
  return get<EmailTemplates[]>(`/email-templates/organization/${organizationId}`)
}
