import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { Recruiter, RecruiterCreateOrUpdateBody, RecruiterCreateOrUpdateResponse } from '@/types/recruiter'

export function getRecruiters() {
  return get<Recruiter[]>('/recruiters')
}

export function createRecruiter(body: RecruiterCreateOrUpdateBody) {
  return post<RecruiterCreateOrUpdateBody, RecruiterCreateOrUpdateResponse>('/recruiters', body)
}

export function updateRecruiter(recruiterId: string, body: RecruiterCreateOrUpdateBody) {
  return put<RecruiterCreateOrUpdateBody, RecruiterCreateOrUpdateResponse>(`/recruiters/${recruiterId}`, body)
}
