import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const textVariants = cva('leading-none', {
  defaultVariants: {
    align: 'default',
    size: 'default',
    variant: 'default',
    weight: 'default',
  },
  variants: {
    align: {
      center: 'text-center',
      default: '',
    },
    breakAll: {
      true: 'break-all',
    },
    capitalize: {
      true: 'capitalize',
    },
    italic: {
      true: 'italic',
    },
    lineThrough: {
      true: 'line-through',
    },
    size: {
      default: 'text-base',
      sm: 'text-sm',
      xs: 'text-xs',
    },
    truncate: {
      true: 'truncate',
    },
    variant: {
      default: 'text-black',
      green: 'text-green',
      muted: 'text-muted-foreground',
      purple: 'text-purple',
      red: 'text-red',
      white: 'text-white',
      yellow: 'text-yellow',
    },
    weight: {
      default: 'font-normal',
      light: 'font-light',
      medium: 'font-medium',
      semibold: 'font-semibold',
    },
  },
})

interface TextProps extends VariantProps<typeof textVariants> {
  children: React.ReactNode
  as?: 'p' | 'span'
  italic?: boolean
  capitalize?: boolean
  truncate?: boolean
  breakAll?: boolean
  lineThrough?: boolean
}

const Text = ({
  align,
  as = 'p',
  breakAll,
  capitalize,
  children,
  italic,
  lineThrough,
  size,
  truncate,
  variant,
  weight,
}: TextProps) => {
  const Comp = as

  return (
    <Comp
      className={cn(
        textVariants({ align, breakAll, capitalize, italic, lineThrough, size, truncate, variant, weight }),
      )}
    >
      {children}
    </Comp>
  )
}

Text.displayName = 'Text'

export { Text }
