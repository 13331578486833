import { GraduationCap } from 'lucide-react'

import { EmptyState } from '@/components/empty-state'
import { Scoring } from '@/components/scoring'
import { Text } from '@/components/ui/text'
import { cn } from '@/lib/utils'
import { ApplicantInfo } from '@/types/application/applicant-info'
import { getQualificationMatchingColor } from '@/utils/color'

interface QualificationsDataProps {
  data: ApplicantInfo['qualificationsData']
}

export function QualificationsData({ data }: QualificationsDataProps) {
  if (!data.scoring) return <EmptyState icon={GraduationCap} title="No qualifications data." />

  const matching = data.matching && Object.values(data.matching)

  return (
    <div className="flex flex-col gap-y-4">
      {Object.entries(data.scoring).map(([title, items], index) => {
        return (
          <div className="flex flex-col gap-y-2" key={title}>
            <div className="flex items-center justify-between capitalize">
              <Text size="sm" weight="semibold">
                {title}
              </Text>
              {matching && <Scoring max={5} value={matching[index]} />}
            </div>
            <div className="flex flex-wrap gap-2 rounded border p-4">
              {Object.entries(items).map(([name, value]) => {
                const currentColor = getQualificationMatchingColor(value)

                return (
                  <div className="flex flex-col items-center" key={name}>
                    <div
                      className={cn('flex items-center gap-x-1 rounded-full border px-2 py-0.5 text-sm font-medium')}
                    >
                      <span className={cn('size-2 shrink-0 rounded-full', `bg-${currentColor}`)}></span>
                      {name}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
