import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'

import { FilesDocuments } from './files-documents'
import { FilesDropzone } from './files-dropzone'

export function FilesAccordion() {
  return (
    <Accordion className="space-y-4" defaultValue="dropzone" type="single">
      <AccordionItem value="dropzone">
        <AccordionTrigger className="bg-muted">Dropzone</AccordionTrigger>
        <AccordionContent>
          <FilesDropzone />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="documents">
        <AccordionTrigger className="bg-muted">Documents</AccordionTrigger>
        <AccordionContent>
          <FilesDocuments />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
