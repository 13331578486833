import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="toaster group"
      richColors
      toastOptions={{
        classNames: {
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          description: 'whitespace-pre-wrap',
          error: 'group-[.toaster]:text-destructive',
          info: 'group-[.toaster]:text-primary',
          success: 'group-[.toaster]:text-green',
          toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:shadow-lg',
          warning: 'group-[.toaster]:text-yellow',
        },
      }}
      {...props}
    />
  )
}

export { Toaster }
