import { MessageSquareText } from 'lucide-react'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'

interface LastCommentProps {
  value?: string
}

export function LastComment({ value }: LastCommentProps) {
  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger>
        <MessageSquareText className={cn('text-muted-foreground/40', value && 'text-muted-foreground/80')} size={16} />
      </TooltipTrigger>
      <TooltipContent align="start" className="max-w-80">
        {value ?? 'No comment'}
      </TooltipContent>
    </Tooltip>
  )
}
