import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'

import { LoadingWrapper } from '@/components/loading-wrapper'
import { Button } from '@/components/ui/button'
import { Title } from '@/components/ui/title'
import { applicationsActivitiesQuery, applicationsLastActivitiesQuery } from '@/hooks/use-applications-queries'

import { Activity } from './activity'
import { ActivityForm } from './activity-form'

export function Activities() {
  const [isLoadMore, setIsLoadMore] = useState(false)
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const { data, isLoading: isLastActivitiesLoading } = useQuery(applicationsLastActivitiesQuery(applicationId))
  const { data: allActivities, isLoading: isAllActivitiesLoading } = useQuery({
    ...applicationsActivitiesQuery(applicationId),
    enabled: isLoadMore,
  })

  return (
    <aside className="flex h-full flex-col overflow-y-auto overflow-x-hidden border-l p-8">
      <Title as="h4" level={6}>
        Activities & Comments
      </Title>
      <ActivityForm />
      <div className="flex-1">
        <div className="flex flex-col gap-y-4 py-4">
          <LoadingWrapper isLoading={isLastActivitiesLoading}>
            {data?.items?.map((item, index) => <Activity item={item} key={`${item.performerId}-${index}`} />)}
          </LoadingWrapper>
          <LoadingWrapper isLoading={isAllActivitiesLoading}>
            {allActivities?.map((item, index) => <Activity item={item} key={`${item.performerId}-${index}`} />)}
          </LoadingWrapper>
        </div>
        {data?.hasMore && !allActivities?.length && (
          <div className="text-center">
            <Button onClick={() => setIsLoadMore(true)} variant="outline">
              Load more activities
            </Button>
          </div>
        )}
      </div>
    </aside>
  )
}
