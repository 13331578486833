import { JobFilterValues } from '@/schemas/job'
import { PutJobStageRefusalStrategyValues, PutOrPostJobStageValues } from '@/schemas/jobs/jobId/stage'
import { axiosDelete } from '@/services/delete'
import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { UpdateAutomationActiveBody } from '@/types/automation'
import { CreateOrUpdateAutomationCommentBody } from '@/types/automation/create-or-update-comment'
import { CreateOrUpdateAutomationEmailCandidateBody } from '@/types/automation/create-or-update-email-candidate'
import { CreateOrUpdateAutomationEmailRecruiterBody } from '@/types/automation/create-or-update-email-recruiter'
import { CreateOrUpdateAutomationScheduleInterviewBody } from '@/types/automation/create-or-update-schedule-interview'
import { CreateOrUpdateAutomationTaskRecruiterBody } from '@/types/automation/create-or-update-task-recruiter'
import {
  Job,
  JobDescription,
  JobUpdateDescriptionBody,
  JobInformations,
  JobSearch,
  JobSettingsRecruiter,
  JobSettingsRefusalReason,
  JobSettingsStage,
  JobCreateOrUpdateInformationsBody,
  JobUpdateStagesOrderBody,
  JobUpdateStagesOrderResponse,
  JobSettingsStageAutomations,
  JobSettingsStageAutomation,
  GetJobInternalInformationsResponse,
  PutJobInternalInformationsBody,
} from '@/types/job'
import { BulkApplicationsBody } from '@/types/job/bulk-applications'
import { JobBoard } from '@/types/job/job-board'
import { JobBoardAutomations } from '@/types/job/job-board-automations'
import { JobStatusResponse } from '@/types/job/status'
import { JobUpdateStatusBody } from '@/types/job/update-status'

/*
 ** Generic
 */

export function getJobs(filters: JobFilterValues) {
  return get<Job[]>('/jobs', {
    params: filters,
  })
}

export function getJobsBoard(jobId: string) {
  return get<JobBoard>(`/jobs/${jobId}/board`)
}

export function getJobsBoardAutomations(jobId: string) {
  return get<JobBoardAutomations>(`/jobs/${jobId}/board/automations`)
}

export function createJob(body: JobCreateOrUpdateInformationsBody) {
  return post<JobCreateOrUpdateInformationsBody, { id: string }>('/jobs', body)
}

export function createJobFromTemplate(id: string) {
  return post<{ id: string }, { id: string }>('/jobs/from-template', { id })
}

export function getJobsSearch(query?: string) {
  return get<JobSearch[]>('/jobs/search', {
    params: {
      ...(query && { q: query }),
    },
  })
}
export function updateJobBulkApplications(jobId: string, body: BulkApplicationsBody) {
  return put<BulkApplicationsBody, Job[]>(`/jobs/${jobId}/bulk`, body)
}

export function getJobsRefusalReasons(jobId: string) {
  return get<JobSettingsRefusalReason[]>(`/jobs/${jobId}/refusal-reasons`)
}

/*
 ** Informations
 */
export function getJobsInformations(jobId: string) {
  return get<JobInformations>(`/jobs/${jobId}/info`)
}

export function updateJobsInformations(jobId: string, body: JobCreateOrUpdateInformationsBody) {
  return put<JobCreateOrUpdateInformationsBody, JobInformations>(`/jobs/${jobId}/info`, body)
}

/*
 ** Description
 */
export function getJobsDescription(jobId: string) {
  return get<JobDescription>(`/jobs/${jobId}/description`)
}

export function updateJobsDescription(jobId: string, body: JobUpdateDescriptionBody) {
  return put<JobUpdateDescriptionBody, JobDescription>(`/jobs/${jobId}/description`, body)
}

/*
 ** Stages
 */
export function getJobsStages(id: string) {
  return get<JobSettingsStage>(`/jobs/${id}/stages`)
}

export function createJobsStage(jobId: string, body: PutOrPostJobStageValues) {
  return post<PutOrPostJobStageValues, { id: string }>(`/jobs/${jobId}/stages`, body)
}

export function updateJobsStagesOrder(jobId: string, body: JobUpdateStagesOrderBody) {
  return put<JobUpdateStagesOrderBody, JobUpdateStagesOrderResponse>(`/jobs/${jobId}/stages`, body)
}

export function updateJobsStage(jobId: string, stageId: string, body: PutOrPostJobStageValues) {
  return put<PutOrPostJobStageValues, { id: string }>(`/jobs/${jobId}/stages/${stageId}`, body)
}

export function deleteJobsStage(jobId: string, stageId: string) {
  return axiosDelete<{ id: string }>(`/jobs/${jobId}/stages/${stageId}`, { customErrorHandling: true })
}

export function updateJobsStageRefusalStrategy(jobId: string, stageId: string, body: PutJobStageRefusalStrategyValues) {
  return put<PutJobStageRefusalStrategyValues, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/refusal-strategy`,
    body,
  )
}

/*
 ** Internal informations
 */

export function getJobsInternalInformations(jobId: string) {
  return get<GetJobInternalInformationsResponse>(`/jobs/${jobId}/internal-informations`)
}

export function updateJobsInternalInformations(jobId: string, body: PutJobInternalInformationsBody) {
  return put<PutJobInternalInformationsBody, { id: string }>(`/jobs/${jobId}/internal-informations`, body)
}

// automations
export function getJobsStagesAutomations(jobId: string, stageId?: string) {
  return get<JobSettingsStageAutomations[]>(`/jobs/${jobId}/stages/${stageId}/automations`)
}

export function getJobsStagesAutomation<T>(jobId: string, stageId?: string, automationId?: string) {
  return get<JobSettingsStageAutomation<T>>(`/jobs/${jobId}/stages/${stageId}/automations/${automationId}`)
}

export function createJobsStagesAutomationsEmailCandidate(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationEmailCandidateBody,
) {
  return post<CreateOrUpdateAutomationEmailCandidateBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-candidate`,
    body,
  )
}

export function updateJobsStagesAutomationsEmailCandidate({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationEmailCandidateBody
}) {
  return put<CreateOrUpdateAutomationEmailCandidateBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-candidate/${automationId}`,
    body,
  )
}

export function createJobsStagesAutomationsEmailRecruiter(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationEmailRecruiterBody,
) {
  return post<CreateOrUpdateAutomationEmailRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-recruiter`,
    body,
  )
}

export function updateJobsStagesAutomationsEmailRecruiter({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationEmailRecruiterBody
}) {
  return put<CreateOrUpdateAutomationEmailRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-recruiter/${automationId}`,
    body,
  )
}

export function createJobsStagesAutomationsComment(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationCommentBody,
) {
  return post<CreateOrUpdateAutomationCommentBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/comment`,
    body,
  )
}

export function updateJobsStagesAutomationsComment({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationCommentBody
}) {
  return put<CreateOrUpdateAutomationCommentBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/comment/${automationId}`,
    body,
  )
}

export function createJobsStagesAutomationsTaskRecruiter(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationTaskRecruiterBody,
) {
  return post<CreateOrUpdateAutomationTaskRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/task-recruiter`,
    body,
  )
}

export function updateJobsStagesAutomationsTaskRecruiter({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationTaskRecruiterBody
}) {
  return put<CreateOrUpdateAutomationTaskRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/task-recruiter/${automationId}`,
    body,
  )
}

export function createJobsStagesAutomationsScheduleInterview(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationScheduleInterviewBody,
) {
  return post<CreateOrUpdateAutomationScheduleInterviewBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/schedule-interview`,
    body,
  )
}

export function updateJobsStagesAutomationsScheduleInterview({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationScheduleInterviewBody
}) {
  return put<CreateOrUpdateAutomationScheduleInterviewBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/schedule-interview/${automationId}`,
    body,
  )
}

export function deleteJobsStagesAutomations(jobId: string, stageId: string, automationId: string) {
  return axiosDelete<{ id: string }>(`/jobs/${jobId}/stages/${stageId}/automations/${automationId}`)
}

export function updateJobsStagesAutomationsActive(
  jobId: string,
  stageId: string,
  automationId: string,
  body: UpdateAutomationActiveBody,
) {
  return put<UpdateAutomationActiveBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/${automationId}/active`,
    body,
  )
}

/*
 ** Recruiters
 */
export function getJobsRecruiters(jobId: string) {
  return get<JobSettingsRecruiter[]>(`/jobs/${jobId}/recruiters`)
}

export function updateJobsRecruiters(jobId: string, recruitersId: string[]) {
  return put<{ recruiters: string[] }, JobSettingsRecruiter[]>(`/jobs/${jobId}/recruiters`, {
    recruiters: recruitersId,
  })
}

/*
 ** Status
 */
export function getJobsStatus(jobId: string) {
  return get<JobStatusResponse>(`/jobs/${jobId}/status`)
}

export function updateJobsStatus(jobId: string, body: JobUpdateStatusBody) {
  return put<{ status: string }, { id: string }>(`/jobs/${jobId}/status`, body)
}

/*
 ** Publish
 */
export function publishJob(jobId: string) {
  return put<{ id: string }, { id: string }>(`/jobs/${jobId}/publish`)
}
