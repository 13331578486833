import { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { EmailTemplatesSelect } from '@/components/form/email-template-select'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { emailTemplateQuery } from '@/hooks/use-email-templates-queries'
import { useCreateEmailsToSendCustom } from '@/queries/use-emails-to-send-mutations'
import { emailsToSendCustomSchema, EmailsToSendCustomValues } from '@/schemas/emails-to-send/custom'

interface ContactFormProps {
  onClose: () => void
}

export function ContactForm({ onClose }: ContactFormProps) {
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const form = useForm<EmailsToSendCustomValues>({
    defaultValues: {
      applicationId,
      body: '',
      emailTemplateId: '',
      subject: '',
    },
    resolver: zodResolver(emailsToSendCustomSchema),
  })
  const { control, handleSubmit, setValue, watch } = form
  const emailTemplateId = watch('emailTemplateId')
  const { data: emailTemplate } = useQuery(emailTemplateQuery(emailTemplateId))
  const { mutate: createEmailsToSendCustom } = useCreateEmailsToSendCustom()

  useEffect(() => {
    if (emailTemplate) {
      setValue('subject', emailTemplate.subject)
      setValue('body', emailTemplate.body)
    }
  }, [emailTemplate, setValue])

  const onSubmit = (data: EmailsToSendCustomValues) => {
    const { applicationId, body, subject } = data
    createEmailsToSendCustom(
      {
        body: {
          applicationId,
          body,
          subject,
        },
      },
      {
        onSuccess: () => {
          toast.success('Email sent to applicant successfully.')
          onClose()
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Template"
          name="emailTemplateId"
          renderSkeleton={(field) => <EmailTemplatesSelect onChange={field.onChange} value={field.value} />}
        />
        <CustomFormField
          control={control}
          fieldType={FormFieldType.INPUT}
          label="Subject"
          name="subject"
          placeholder="Email subject"
        />
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Content"
          name="body"
          placeholder="Email content"
          renderSkeleton={(field) => <Textarea {...field} className="min-h-96" />}
        />

        <Button className="self-center" type="submit">
          Send email
        </Button>
      </form>
    </Form>
  )
}
