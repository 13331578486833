import { Text } from '@/components/ui/text'
import { useCountdown } from '@/hooks/use-countdown'

interface CountDownProps {
  refusedDate: Date
}

export function CountDown({ refusedDate }: CountDownProps) {
  const [days, hours, minutes] = useCountdown(refusedDate)

  return (
    <Text size="xs" variant="red">
      {!!days && `${days}d `}
      {!!hours && `${hours}h `}
      {!!minutes && `${minutes}m`}
    </Text>
  )
}
