import { create } from 'zustand'

interface BulkActionsStore {
  isOpen: boolean
  toggleOpen: () => void
  selected: string[]
  setSelected: (selected: string[]) => void
  clearSelected: () => void
  onClose: () => void
}

export const useBulkActions = create<BulkActionsStore>()((set) => ({
  clearSelected: () => set({ selected: [] }),
  isOpen: false,
  onClose: () => set({ isOpen: false }),
  selected: [],
  setSelected: (selected) => set({ selected }),
  toggleOpen: () => set((state) => ({ isOpen: !state.isOpen, selected: [] })),
}))
