import { useQuery } from '@tanstack/react-query'

import { Select } from '@/components/select'
import { refusalReasonsQuery } from '@/hooks/use-refusal-reasons-queries'

interface RefusalReasonSelectProps {
  value: string
  onChange: (value: string) => void
}

export function RefusalReasonSelect({ onChange, value }: RefusalReasonSelectProps) {
  const { data: refusalReasons, isLoading } = useQuery(refusalReasonsQuery)

  return (
    <Select
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isLoading={isLoading}
      onChange={(value) => value && onChange(value.id)}
      options={refusalReasons}
      value={refusalReasons?.find((opt) => opt.id === value)}
    />
  )
}
