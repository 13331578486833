import { put } from '@/services/put'
import { UpdateInterviewsBody } from '@/types/interviews/note'

export function finishInterviews(interviewId: string) {
  return put<unknown, { id: string }>(`/interviews/${interviewId}/done`)
}

export function updateInterviewsNote(interviewId: string, body: UpdateInterviewsBody) {
  return put<UpdateInterviewsBody, { id: string }>(`/interviews/${interviewId}/note`, body)
}

export function cancelInterview(interviewId: string) {
  return put<unknown, { id: string }>(`/interviews/${interviewId}/cancel`)
}

export function rescheduleInterview(interviewId: string) {
  return put<unknown, { id: string }>(`/interviews/${interviewId}/reschedule`)
}
