import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { FolderOpen } from 'lucide-react'

import { EmptyState } from '@/components/empty-state'
import { applicationsDocumentsQuery } from '@/queries/use-applications-queries'

import { Document } from './document'

export function FilesDocuments() {
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const { data: documents } = useQuery(applicationsDocumentsQuery(applicationId))

  if (documents?.length === 0) {
    return <EmptyState icon={FolderOpen} title="No documents" />
  }

  return (
    <div className="grid grid-cols-2 gap-4 xl:grid-cols-3 2xl:grid-cols-4">
      {documents?.map((document) => <Document key={document.id} {...document} />)}
    </div>
  )
}
