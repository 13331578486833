import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

export const putApplicantSchema = z.object({
  availability: z.date({ invalid_type_error: 'Availability must be a date' }).optional().nullable(),
  city: z.string().optional(),
  countryId: z.string().min(1, { message: 'Country is required.' }),
  currentCompany: z.string().optional(),
  currentJob: z.string().optional(),
  email: z.string().email().min(1, { message: 'Email is required.' }),
  expectedContractType: z.string().optional().nullable(),
  expectedSalary: z.number().optional().nullable(),
  firstname: z.string().min(1, { message: 'First name is required.' }),
  lastname: z.string().min(1, { message: 'Last name is required.' }),
  linkedinUrl: z
    .string()
    .regex(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/, 'Invalid URL')
    .optional(),
  nationalityId: z.string().min(1, { message: 'Nationality is required.' }),
  phone: z.string().refine((value) => isValidPhoneNumber(value), { message: 'Invalid phone number.' }),
  yearOfExperience: z.number().optional().nullable(),
})

export type PutApplicantValues = z.infer<typeof putApplicantSchema>
