import axios from 'axios'

import { LoginValues, ResetPasswordValues } from '@/schemas/auth'
import { put } from '@/services/put'

export async function login({ email, password }: LoginValues) {
  try {
    const response = await axios.post<{ accessToken: string }>(`${import.meta.env.VITE_API_URL as string}/auth/login`, {
      email,
      password,
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function refreshToken() {
  return put<undefined, { accessToken: string }>('/auth/refresh-token', undefined)
}

export async function forgotPassword(email: string) {
  try {
    const response = await axios.post<undefined>(`${import.meta.env.VITE_API_URL as string}/auth/forgot-password`, {
      email,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function resetPassword({ confirmation, email, password, token }: ResetPasswordValues) {
  try {
    const response = await axios.post<undefined>(`${import.meta.env.VITE_API_URL as string}/auth/reset-password`, {
      confirmation,
      email,
      password,
      token,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function createPassword({ confirmation, email, password, token }: ResetPasswordValues) {
  try {
    const response = await axios.post<{ accessToken: string }>(
      `${import.meta.env.VITE_API_URL as string}/auth/create-password`,
      {
        confirmation,
        email,
        password,
        token,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
