import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { Title } from '@/components/ui/title'

export const Route = createFileRoute('/_authenticated/applicants')({
  component: ApplicantsIndex,
})

function ApplicantsIndex() {
  return (
    <PageContent>
      <Title>Applicants view</Title>
    </PageContent>
  )
}
