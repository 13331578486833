import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { JobBoardApplicationWithStageId } from '@/types/application/job-board'

import { Card } from './card'

export function SortableCard({ application, color }: { application: JobBoardApplicationWithStageId; color: string }) {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    data: {
      application,
      type: 'card',
    },
    id: application.id,
  })

  const style = {
    opacity: isDragging ? 0.3 : 1,
    transform: CSS.Transform.toString(transform),
    transition,
  }

  if (isDragging) {
    return <Card application={application} color={color} ref={setNodeRef} style={style} />
  }

  return <Card application={application} color={color} ref={setNodeRef} style={style} {...attributes} {...listeners} />
}
