import { MinusCircleIcon } from 'lucide-react'
import { Control, useFieldArray, useFormContext } from 'react-hook-form'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { ConfigSelect, ConfigType } from '@/components/form/config-select'
import { LocationSelect } from '@/components/form/location-select'
import { Select } from '@/components/select'
import { Button } from '@/components/ui/button'
import { FormControl } from '@/components/ui/form'
import { Text } from '@/components/ui/text'
import { Entity } from '@/schemas/entity'

export const EMPTY_LOCATION = [{ frequency: '', location: undefined, type: '' }]

type LocationType = {
  type: string
  location?: Entity
  frequency: string
}

type ControlFieldValues = {
  jobLocations: LocationType[]
}

interface LocationFieldsProps<T extends ControlFieldValues> {
  control: T extends ControlFieldValues ? Control<T> : never
}

const LOCATION_OPTIONS = [
  { label: 'Office', value: 'OFFICE' },
  { label: 'Onshore', value: 'ON_SITE' },
  { label: 'Offshore', value: 'OFFSHORE' },
]

const FREQUENCY_TYPES: Record<string, ConfigType> = {
  OFFICE: 'officeJobFrequencies',
  OFFSHORE: 'offshoreJobFrequencies',
  ON_SITE: 'onshoreJobFrequencies',
}

export function LocationFields<T extends ControlFieldValues>({ control }: LocationFieldsProps<T>) {
  const { formState, watch } = useFormContext<ControlFieldValues>()

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'jobLocations',
  })

  const watchFieldArray = watch('jobLocations')

  return (
    <div className="space-y-2">
      <Text size="sm" variant={formState.errors.jobLocations && 'red'} weight="medium">
        Locations
      </Text>
      <div className="space-y-2">
        {fields.map((field, index) => {
          const type = watchFieldArray[index].type

          return (
            <div className="grid grid-cols-[repeat(3,_1fr)_36px] gap-x-2" key={field.id}>
              <CustomFormField
                control={control}
                fieldType={FormFieldType.SKELETON}
                name={`jobLocations.${index}.type`}
                renderSkeleton={(field) => (
                  <FormControl>
                    <Select
                      onChange={(value) => field.onChange(value?.value)}
                      options={LOCATION_OPTIONS}
                      placeholder="Select type..."
                      value={LOCATION_OPTIONS?.find((opt) => opt.value === field.value)}
                    />
                  </FormControl>
                )}
              />
              <CustomFormField
                control={control}
                fieldType={FormFieldType.SKELETON}
                name={`jobLocations.${index}.location`}
                renderSkeleton={(field) => (
                  <FormControl>
                    <LocationSelect onChange={field.onChange} placeholder="Select city..." value={field.value} />
                  </FormControl>
                )}
              />
              <CustomFormField
                control={control}
                fieldType={FormFieldType.SKELETON}
                name={`jobLocations.${index}.frequency`}
                renderSkeleton={(field) => (
                  <FormControl>
                    <ConfigSelect
                      onChange={field.onChange}
                      type={type ? FREQUENCY_TYPES[type] : 'officeJobFrequencies'}
                      value={field.value}
                    />
                  </FormControl>
                )}
              />
              {fields.length > 1 && (
                <Button onClick={() => remove(index)} size="icon" variant="ghost">
                  <MinusCircleIcon size={16} />
                </Button>
              )}
            </div>
          )
        })}
      </div>
      <Button onClick={() => append(EMPTY_LOCATION)} variant="secondary">
        Add location
      </Button>
    </div>
  )
}
