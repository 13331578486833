import { ReactNode, useEffect, useMemo, useState } from 'react'

import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { useParams } from '@tanstack/react-router'
import { createPortal } from 'react-dom'
import { toast } from 'sonner'

import { useUpdateApplicationsStage } from '@/hooks/use-applications-queries'
import { JobBoardApplicationWithStageId } from '@/types/application/job-board'
import { JobBoardColumn, JobBoardStage } from '@/types/stage/job-board'

import { BulkActions } from './bulk-actions'
import { Card } from './card'
import { Column } from './column'
import { isDroppable } from './utils'

interface BoardProps {
  stages: JobBoardStage[]
}

const formatStages = (stages: JobBoardStage[]): JobBoardColumn[] => {
  return stages.map((stage) => ({
    color: stage.color,
    count: stage.applications.length,
    id: stage.id,
    isDroppable: isDroppable(stage.order),
    name: stage.name,
    order: stage.order,
  }))
}

const formatApplications = (stages: JobBoardStage[]): JobBoardApplicationWithStageId[] => {
  return stages.flatMap((stage) => {
    return stage.applications.map((application) => {
      return {
        ...application,
        fromStageId: stage.id,
        isDroppable: isDroppable(stage.order),
        stageId: stage.id,
        stageName: stage.name,
      }
    })
  })
}

export function Board({ stages }: BoardProps) {
  const { jobId } = useParams({ from: '/_authenticated/jobs/$jobId' })
  const { mutate: updateApplicationsStage } = useUpdateApplicationsStage(jobId)

  const columns = useMemo(() => formatStages(stages), [stages])
  const columnsIds = useMemo(() => columns.map((column) => column.id), [columns])

  const [activeColor, setActiveColor] = useState('')
  const [applications, setApplications] = useState<JobBoardApplicationWithStageId[]>(formatApplications(stages))
  const [activeApplication, setActiveApplication] = useState<JobBoardApplicationWithStageId | null>(null)

  useEffect(() => {
    setApplications(formatApplications(stages))
  }, [stages])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // distance in pixels
      },
    }),
  )

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'card') {
      const active = event.active.data.current.application as JobBoardApplicationWithStageId

      if (active) {
        setActiveApplication(active)
        const currentColumn = columns.find((column) => column.id === active.stageId)
        setActiveColor(currentColumn?.color || '')
      }

      return
    }
  }

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event

    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveACard = active.data.current?.type === 'card'
    const isOverACard = over.data.current?.type === 'card'

    if (!isActiveACard) return

    // im dropping a task over another task
    if (isActiveACard && isOverACard) {
      const overCard = over.data.current?.application as JobBoardApplicationWithStageId
      if (overCard.isDroppable) {
        setApplications((applications) => {
          const activeIndex = applications.findIndex((application) => application.id === active.id)
          const overIndex = applications.findIndex((application) => application.id === over.id)

          applications[activeIndex].stageId = applications[overIndex].stageId

          return arrayMove(applications, activeIndex, overIndex)
        })
      }
    }

    // im dropping a task over a column
    const isOverAColumn = over.data.current?.type === 'column'
    if (isActiveACard && isOverAColumn) {
      const overColumn = over.data.current?.column as JobBoardColumn
      const activeCard = active.data.current?.application as JobBoardApplicationWithStageId
      if (overColumn.isDroppable || !activeCard.isDroppable) {
        setApplications((applications) => {
          const activeIndex = applications.findIndex((application) => application.id === active.id)

          applications[activeIndex].stageId = overId as string

          return arrayMove(applications, activeIndex, activeIndex)
        })
      }
    }
  }

  const onDragEnd = (event: DragEndEvent) => {
    const { over } = event
    if (!over) return
    let overApplication = null
    if (over.data.current?.type === 'column') {
      const overColumn = over.data.current?.column as JobBoardColumn
      if (!overColumn.isDroppable) {
        return false
      }
      overApplication = applications.find((application) => application.stageId === over.id)
    } else {
      const overCard = over.data.current?.application as JobBoardApplicationWithStageId
      if (!overCard.isDroppable && overCard.id !== activeApplication?.id) {
        return false
      }
      overApplication = applications.find((application) => application.id === over.id)
    }

    if (!activeApplication || !overApplication) return
    if (activeApplication.fromStageId === overApplication.stageId) return

    updateApplicationsStage(
      {
        applicationId: activeApplication.id,
        body: { fromStageId: activeApplication.fromStageId, toStageId: overApplication.stageId },
      },
      {
        onError: () => {
          toast.error(`Error updating stage's application`)
        },
        onSettled: () => {
          setActiveApplication(null)
        },
        onSuccess: () => {
          toast.success(`Applicant moved successfully.`)
        },
      },
    )
  }

  return (
    <DndContext onDragEnd={onDragEnd} onDragOver={onDragOver} onDragStart={onDragStart} sensors={sensors}>
      <div className="flex gap-x-4 ">
        <SortableContext items={columnsIds}>
          {columns.map((column) => (
            <Column
              applications={applications.filter((application) => application.stageId === column.id)}
              column={column}
              key={column.id}
            />
          ))}
        </SortableContext>
      </div>
      <BulkActions />
      {createPortal(
        <DragOverlay>
          {activeApplication && <Card application={activeApplication} color={activeColor} overlay />}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  )
}

export function BoardSkeleton({ children }: { children: ReactNode }) {
  return <div className="flex h-full gap-x-4 overflow-auto p-8">{children}</div>
}
