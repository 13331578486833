import { queryOptions } from '@tanstack/react-query'

import { getEmailTemplate, getEmailTemplates, getEmailTemplatesByOrganization } from '@/api/email-templates'

/**
 * Query options
 */

// GET /email-templates
export const emailTemplatesQuery = {
  queryFn: getEmailTemplates,
  queryKey: ['emailTemplates'],
  staleTime: Infinity,
}

// GET /email-templates/:id
export const emailTemplateQuery = (id?: string) =>
  queryOptions({
    enabled: !!id,
    queryFn: () => getEmailTemplate(id),
    queryKey: ['emailTemplates', id],
  })

// GET /email-templates/organization/:organizationId
export const emailTemplatesByOrganizationQuery = (organizationId: string) =>
  queryOptions({
    queryFn: () => getEmailTemplatesByOrganization(organizationId),
    queryKey: ['emailTemplatesByOrganization', organizationId],
  })
