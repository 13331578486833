import { createFileRoute } from '@tanstack/react-router'
import { FileBarChart, FileText, FileType, HeartHandshake } from 'lucide-react'

import { PageContent } from '@/components/page-content'
import { PageHeader } from '@/components/page-header'
import { Sidebar } from '@/components/sidebar'
import { CreateOrUpdateForm } from '@/features/jobs/edit/index/create-or-update-form'

export const Route = createFileRoute('/_authenticated/jobs/create')({
  component: JobCreate,
})

const SIDEBAR = [
  {
    exact: true,
    icon: FileType,
    label: 'Job informations',
    to: '/jobs/create',
  },
  {
    disabled: true,
    icon: FileText,
    label: 'Job description',
    to: '/jobs/$jobId/edit/description',
  },
  {
    disabled: true,
    icon: FileBarChart,
    label: 'Stages',
    to: '/jobs/$jobId/edit/stages',
  },
  {
    disabled: true,
    icon: HeartHandshake,
    label: 'Recruiters',
    to: '/jobs/$jobId/edit/recruiters',
  },
]

function JobCreate() {
  return (
    <div className="flex">
      <Sidebar links={SIDEBAR} />
      <div className="w-full">
        <PageHeader title="Job informations" />
        <PageContent>
          <CreateOrUpdateForm />
        </PageContent>
      </div>
    </div>
  )
}
