import { useBulkActions } from '@/hooks/use-bulk-actions'
import { cn } from '@/lib/utils'

import { MoveToStageAction } from './bulk-actions/move-to-stage-action'
import { RefuseAction } from './bulk-actions/refuse-action'
import { SendEmailAction } from './bulk-actions/send-email-action'

export function BulkActions() {
  const { isOpen } = useBulkActions()

  return (
    <div
      className={cn(
        'fixed inset-x-0 bottom-0 z-0 flex h-16 items-center justify-between gap-x-2 border-t bg-background px-8 translate-y-full transition-transform',
        isOpen && 'translate-y-0',
      )}
    >
      <div className="flex gap-x-2">
        <SendEmailAction />
        <MoveToStageAction />
      </div>
      <RefuseAction />
    </div>
  )
}
