import { PostInterviewValues } from '@/schemas/interviews/create'

export type InterviewApplication = {
  applicationId: string
  interviews: Interview[]
}

export enum InterviewStatus {
  CANCELED = 'canceled',
  RE_SCHEDULED = 're-scheduled',
  SCHEDULED = 'scheduled',
  DONE = 'done',
  TO_SCHEDULE = 'to schedule',
  DIRECT_CALL = 'direct call',
}

type Interview = {
  duration: number
  id: string
  location: string
  note: string
  scheduledAt: string
  stageName: string
  status: keyof typeof InterviewStatus
  type: string
  videoService: string
}

export type CreateApplicationInterviewBody = PostInterviewValues
