import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { Title } from '@/components/ui/title'

export const Route = createFileRoute('/_authenticated/statistics')({
  component: StatisticsIndex,
})

function StatisticsIndex() {
  return (
    <PageContent>
      <Title>Statistics view</Title>
    </PageContent>
  )
}
