import { z } from 'zod'

const conditionSchema = z.object({
  condition: z.string(),
  field: z.string(),
  value: z.string(),
})

export const putOrPostAutomationsEmailCandidateSchema = z.object({
  conditions: z.array(conditionSchema),
  delay: z.number().nonnegative(),
  emailTemplateId: z.string().min(1, {
    message: 'Email template is required.',
  }),
  from: z.string().min(1, {
    message: 'Recruiter is required.',
  }),
})

export const automationStepEnum = z.enum([
  'comment',
  'create',
  'email-candidate',
  'email-recruiter',
  'schedule-interview',
  'show',
  'task-recruiter',
])

export type AutomationStep = z.infer<typeof automationStepEnum>

export type PutOrPostAutomationsEmailCandidateValues = z.infer<typeof putOrPostAutomationsEmailCandidateSchema>

export const putOrPostAutomationsEmailRecruiterSchema = z.object({
  conditions: z.array(conditionSchema),
  delay: z.number().nonnegative(),
  emailTemplateId: z.string().min(1, {
    message: 'Email template is required.',
  }),
  from: z.string().min(1, {
    message: 'Recruiter is required.',
  }),
  to: z.string().array().nonempty({
    message: 'At least one recruiter is required',
  }),
})

export type PutOrPostAutomationsEmailRecruiterValues = z.infer<typeof putOrPostAutomationsEmailRecruiterSchema>

export const putOrPostAutomationsTaskRecruiterSchema = z.object({
  conditions: z.array(conditionSchema),
  // after: z.string().optional(),
  content: z.string().min(1, {
    message: 'Content is required.',
  }),
  dueDate: z.date().optional().nullable(),
  lead: z.string().min(1, {
    message: 'Lead is required.',
  }),
  performers: z.string().array(),
  priority: z.string().min(1, {
    message: 'Priority is required.',
  }),
  status: z.string().min(1, {
    message: 'Status is required.',
  }),
})

export type PutOrPostAutomationsTaskRecruiterValues = z.infer<typeof putOrPostAutomationsTaskRecruiterSchema>

export const putOrPostAutomationsCommentSchema = z.object({
  comment: z.string().min(1, {
    message: 'Comment is required.',
  }),
  conditions: z.array(conditionSchema),
})

export type PutOrPostAutomationsCommentValues = z.infer<typeof putOrPostAutomationsCommentSchema>

export const putOrPostAutomationsScheduleInterviewSchema = z
  .object({
    conditions: z.array(conditionSchema),
    duration: z.number().min(1, {
      message: 'Duration is required.',
    }),
    location: z.string().optional().nullable(),
    participant: z.string().min(1, {
      message: 'Participant is required.',
    }),
    type: z.string().min(1, {
      message: 'Type is required.',
    }),
    videoService: z.string().optional().nullable(),
  })
  .refine((data) => !(data.type === 'PHYSICAL' && !data.location), {
    message: 'Location is required for physical interview',
    path: ['location'],
  })
  .refine((data) => !(data.type === 'VISIO' && !data.videoService), {
    message: 'Video service is required for visio interview.',
    path: ['videoService'],
  })

export type PutOrPostAutomationsScheduleInterviewValues = z.infer<typeof putOrPostAutomationsScheduleInterviewSchema>
