import { useParams } from '@tanstack/react-router'
import { SquareCheck } from 'lucide-react'
import { toast } from 'sonner'

import { Checkbox } from '@/components/ui/checkbox'
import { Text } from '@/components/ui/text'
import { cn } from '@/lib/utils'
import { useUpdateTasksApplication } from '@/queries/use-tasks-mutations'
import { TaskToDoContent } from '@/types/application/activities'

export function TaskToDo({ status, taskContent, taskId }: TaskToDoContent) {
  const { applicationId } = useParams({ from: '/_authenticated/jobs/$jobId/applications/$applicationId' })
  const { mutate: updateTasksApplication } = useUpdateTasksApplication(taskId, applicationId)

  const handleCheckedChange = (checked: boolean) => {
    if (!checked) return
    updateTasksApplication(
      { status: 'DONE' },
      {
        onSuccess: () => {
          toast.success('Task marked as done.')
        },
      },
    )
  }

  return (
    <div className="flex items-center gap-x-2">
      <SquareCheck className="shrink-0 text-primary" size={16} />
      <Text size="xs" weight="semibold">
        {taskContent}
      </Text>
      <Checkbox
        checked={status === 'DONE'}
        className={cn(status === 'DONE' && 'cursor-not-allowed')}
        onCheckedChange={handleCheckedChange}
      />
    </div>
  )
}
