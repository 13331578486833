import { JobBoardApplication } from '@/types/application/job-board'

import { LastComment } from './components/last-comment'
// import { LastEmail } from './components/last-email'
import { MatchScore } from './components/match-score'
import { UpdateAction } from './refusal-strategy/update-action'

interface CardFooterProps {
  application: JobBoardApplication
}

export function CardFooter({ application }: CardFooterProps) {
  return (
    <div className="flex items-center gap-x-2 border-t px-2 py-1">
      <LastComment value={application.lastComment} />
      {/* <LastEmail value={application.lastEmail} /> */}
      <MatchScore value={application.matchScore} />
      {application.refusalReason && <UpdateAction application={application} />}
      {/* TODO : add lastActivity when Lot 5 is done */}
    </div>
  )
}
