import { queryOptions } from '@tanstack/react-query'

import { getApplicationsDocuments, getApplicationsInterviews } from '@/api/applications'

/**
 * Query options
 */

// GET /applications/:applicationId/documents
export const applicationsDocumentsQuery = (applicationId: string) => {
  return queryOptions({
    queryFn: () => getApplicationsDocuments(applicationId),
    queryKey: ['applicationsDocuments', applicationId],
  })
}

// GET /applications/:applicationId/interviews
export function applicationsInterviewsQuery(applicationId: string) {
  return queryOptions({
    queryFn: () => getApplicationsInterviews(applicationId),
    queryKey: ['applicationsInterviews', applicationId],
    select: (data) => data?.interviews,
  })
}
