import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from '@tanstack/react-router'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { forgotPassword } from '@/api/auth'
import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { ForgotPasswordValues, forgotPasswordSchema } from '@/schemas/auth'

export function ForgotPasswordForm() {
  const form = useForm<ForgotPasswordValues>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(forgotPasswordSchema),
  })
  const navigate = useNavigate()

  const onSubmit = async (values: ForgotPasswordValues) => {
    try {
      await forgotPassword(values.email)
      toast.success('Email sent successfully.')
      await navigate({ to: '/auth/login' })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          form.setError('email', {
            message: 'Invalid email or password',
            type: 'manual',
          })
          return
        }
      }
      toast.error('An error occurred. Please try again.')
    }
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <CustomFormField
          control={form.control}
          fieldType={FormFieldType.INPUT}
          label="Email"
          name="email"
          placeholder="aurelien@aventa.fr"
          type="email"
        />
        <Button isLoading={form.formState.isSubmitting} size="lg" type="submit">
          Send email
        </Button>
      </form>
    </Form>
  )
}
