import { MimeType } from '@/types/application/documents'

interface FilePreviewProps {
  url: string
  mimeType: MimeType
  name: string
}

export function FilePreview({ mimeType, name, url }: FilePreviewProps) {
  const renderDocument = () => {
    if (
      mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      mimeType === 'application/msword'
    ) {
      return (
        <iframe
          className="size-full overflow-hidden border-none"
          src={`https://docs.google.com/gview?url=${url}&embedded=true`}
        ></iframe>
      )
    }
    if (mimeType === 'application/pdf') {
      return <iframe className="size-full overflow-hidden border-none" src={url}></iframe>
    }
    if (mimeType === 'image/jpeg' || mimeType === 'image/png') {
      return <img alt={name} className="size-full object-cover" src={url} />
    }
    return null
  }

  return renderDocument()
}
