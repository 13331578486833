import { ComponentProps } from 'react'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export function PhoneNumberInput({
  onChange,
  value,
}: {
  onChange: ComponentProps<typeof PhoneInput>['onChange']
  value: ComponentProps<typeof PhoneInput>['value']
}) {
  return (
    <PhoneInput
      className="flex h-9 w-full gap-x-1 rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm ring-offset-background transition-all file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      defaultCountry="FR"
      international
      numberInputProps={{
        className: 'w-full focus:outline-none',
        placeholder: 'Enter a phone number',
      }}
      onChange={onChange}
      value={value}
    />
  )
}
