import { queryOptions } from '@tanstack/react-query'

import { getCountries } from '@/api/countries'

/**
 * Query options
 */

// GET /countries
export const countriesQuery = queryOptions({
  queryFn: () => getCountries(),
  queryKey: ['countries'],
  staleTime: Infinity,
})
