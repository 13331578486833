import { z } from 'zod'

export const jobFilterSchema = z.object({
  organizations: z.string().array().optional(),
  recruiters: z.string().array().optional(),
  statuses: z.string().array().optional(),
})

export type JobFilterValues = z.infer<typeof jobFilterSchema>

export const editJobsRecruitersSchema = z.object({
  recruiters: z.string().array().nonempty({
    message: 'At least one recruiter is required',
  }),
})

export type EditJobsRecruitersValues = z.infer<typeof editJobsRecruitersSchema>
