import { createFileRoute, redirect } from '@tanstack/react-router'

import { Title } from '@/components/ui/title'

export const Route = createFileRoute('/_authenticated/')({
  beforeLoad: () => {
    throw redirect({
      to: '/jobs',
    })
  },
  component: Index,
})

function Index() {
  return (
    <div className="flex h-full items-center justify-center">
      <Title>Welcome to the ATS dashboard</Title>
    </div>
  )
}
