import { z } from 'zod'

export const postInterviewSchema = z
  .object({
    duration: z.number(),
    location: z.string().optional(),
    participant: z.string().min(1, {
      message: 'Participant is required',
    }),
    scheduledAt: z.date().optional(),
    timeStatus: z.string().min(1, {
      message: 'Time status is required.',
    }),
    timeZone: z.string().optional(),
    type: z.string().min(1, {
      message: 'Type is required.',
    }),
    videoService: z.string().optional(),
  })
  .refine((data) => !(data.type === 'PHYSICAL' && !data.location), {
    message: 'Location is required for physical interview',
    path: ['location'],
  })
  .refine((data) => !(data.type === 'VISIO' && !data.videoService), {
    message: 'Video service is required for visio interview.',
    path: ['videoService'],
  })
  .refine((data) => !(data.timeStatus === 'SCHEDULED' && !data.scheduledAt), {
    message: 'Scheduled at is required for scheduled interview.',
    path: ['scheduledAt'],
  })

export type PostInterviewValues = z.infer<typeof postInterviewSchema>
