import { useQuery } from '@tanstack/react-query'

import { Select } from '@/components/select'
import { emailTemplatesByOrganizationQuery } from '@/hooks/use-email-templates-queries'
import { useOrganizationId } from '@/hooks/use-organization-id'

interface EmailTemplatesSelectProps {
  value?: string | null
  onChange: (value: string) => void
}

export function EmailTemplatesSelect({ onChange, value }: EmailTemplatesSelectProps) {
  const { organizationId } = useOrganizationId()
  const { data: emailTemplates, isLoading } = useQuery(emailTemplatesByOrganizationQuery(organizationId))

  return (
    <Select
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isLoading={isLoading}
      onChange={(value) => value && onChange(value.id)}
      options={emailTemplates}
      placeholder="Select email template..."
      value={emailTemplates?.find((opt) => opt.id === value)}
    />
  )
}
