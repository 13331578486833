import { create } from 'zustand'

interface OrganizationIdStore {
  organizationId: string
  setOrganizationId: (organizationId: string) => void
}

export const useOrganizationId = create<OrganizationIdStore>()((set) => ({
  organizationId: '',
  setOrganizationId: (organizationId) => set({ organizationId }),
}))
