import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'

import { Header } from '@/components/header'

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated()) {
      throw redirect({
        search: {
          redirect: location.href,
        },
        to: '/auth/login',
      })
    }
  },
  component: Authenticated,
})

function Authenticated() {
  return (
    <>
      <Header />
      <div className="h-full pt-16">
        <Outlet />
      </div>
    </>
  )
}
