import { LinkedInLogoIcon } from '@radix-ui/react-icons'
import { cva } from 'class-variance-authority'

import { cn } from '@/lib/utils'

interface LinkedinLinkProps {
  url: string
  className?: string
  size?: 'default' | 'lg'
}

const linkedinLinkVariants = cva('text-linkedin', {
  defaultVariants: {
    size: 'default',
  },
  variants: {
    size: {
      default: 'size-4',
      lg: 'size-6',
    },
  },
})

export function LinkedinLink({ className, size, url }: LinkedinLinkProps) {
  return (
    <a href={url} onClick={(e) => e.stopPropagation()} rel="noreferrer" target="_blank" title="See LinkedIn profile">
      <LinkedInLogoIcon className={cn(linkedinLinkVariants({ className, size }))} />
    </a>
  )
}
