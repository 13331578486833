import { CalendarClock, CalendarOff } from 'lucide-react'

import { Text } from '@/components/ui/text'
import { InterviewCreatedContent } from '@/types/application/activities'
import { getFormattedDateTime } from '@/utils/date'

export function InterviewCreated({ scheduledAt }: InterviewCreatedContent) {
  return scheduledAt ? (
    <div className="flex items-center gap-x-1 text-green">
      <CalendarClock size={16} />
      <Text size="xs" variant="green">
        {getFormattedDateTime(scheduledAt)}
      </Text>
    </div>
  ) : (
    <div className="flex items-center gap-x-1 text-red">
      <CalendarOff size={16} />
      <Text size="xs" variant="red">
        Not yet scheduled
      </Text>
    </div>
  )
}
