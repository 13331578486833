import { useEffect, useState } from 'react'

import { setHours, setMinutes } from 'date-fns'
import { CalendarIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Select, SelectItem, SelectTrigger, SelectValue, SelectContent } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { getFormattedDateTime } from '@/utils/date'

import { Label } from '../ui/label'

const hours = Array.from({ length: 24 }, (_, i) => i.toString())
const minutes = Array.from({ length: 4 }, (_, i) => (i * 15).toString())

interface DatetimepickerInputProps {
  value?: Date
  onChange: (date: Date) => void
  placeholder?: string
  disabled?: (date: Date) => boolean
}

export const DatetimepickerInput = ({
  disabled,
  onChange,
  placeholder = 'Pick a date',
  value,
}: DatetimepickerInputProps) => {
  const [hour, setHour] = useState<string>('10')
  const [minute, setMinute] = useState<string>('0')
  const [date, setDate] = useState<Date | undefined>(value)

  useEffect(() => {
    if (date) {
      onChange(date)
    }
  }, [date, onChange])

  const setSelectedHour = (selectedHour: string) => {
    setHour(selectedHour)
    setDate(setHours(date ?? new Date(), parseInt(selectedHour)))
  }

  const setSelectedMinute = (selectedMinute: string) => {
    setMinute(selectedMinute)
    setDate(setMinutes(date ?? new Date(), parseInt(selectedMinute)))
  }

  const setSelectedDate = (selectedDate: Date | undefined) => {
    if (!selectedDate) return
    let newDate = setHours(selectedDate, parseInt(hour))
    newDate = setMinutes(newDate, parseInt(minute))

    setDate(newDate)
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className={cn('pl-3 text-left font-normal w-full', !date && 'text-muted-foreground')} variant="outline">
          {date ? getFormattedDateTime(date) : <span>{placeholder}</span>}
          <CalendarIcon className="ml-auto size-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="flex w-full p-0">
        <div className="flex">
          <Calendar autoFocus disabled={disabled} mode="single" onSelect={setSelectedDate} selected={date} />
          <div className="flex gap-x-2 pr-4 pt-4">
            <div className="flex flex-col gap-y-1">
              <Label>Hour</Label>
              <Select onValueChange={setSelectedHour} value={hour}>
                <SelectTrigger className="w-16">
                  <SelectValue placeholder="hour" />
                </SelectTrigger>
                <SelectContent>
                  {hours.map((hour) => (
                    <SelectItem key={hour} value={hour}>
                      {hour}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-y-1">
              <Label>Minutes</Label>
              <Select onValueChange={setSelectedMinute} value={minute}>
                <SelectTrigger className="w-16">
                  <SelectValue placeholder="min" />
                </SelectTrigger>
                <SelectContent>
                  {minutes.map((minute) => (
                    <SelectItem key={minute} value={minute}>
                      {minute}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
