import * as React from 'react'

import { Loader } from 'lucide-react'

import { cn } from '@/lib/utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isLoading?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, disabled, isLoading, type, ...props }, ref) => {
    return (
      <div className="relative w-full">
        <input
          className={cn(
            'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm ring-offset-background shadow-sm transition-all file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          disabled={disabled || isLoading}
          ref={ref}
          type={type}
          {...props}
        />
        {isLoading && (
          <div className="absolute right-0 top-0 flex h-full items-center px-2">
            <Loader className="animate-spin text-muted-foreground" size={16} />
          </div>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
