import { Select } from '../select'

const DURATION_OPTIONS = [
  // {
  //   label: '15 min',
  //   value: 15,
  // },
  {
    label: '30 min',
    value: 30,
  },
  // {
  //   label: '1 hour',
  //   value: 60,
  // },
  // {
  //   label: '2 hours',
  //   value: 120,
  // },
]

interface DurationSelectProps {
  value: number | null
  onChange: (value: number) => void
}

export function DurationSelect({ onChange, value }: DurationSelectProps) {
  return (
    <Select
      onChange={(value) => value && onChange(value?.value)}
      options={DURATION_OPTIONS}
      placeholder="Select duration..."
      value={DURATION_OPTIONS.find((opt) => opt.value === value)}
    />
  )
}
