import { Mail } from 'lucide-react'

import { Text } from '@/components/ui/text'
import { InterviewInviteContent } from '@/types/application/activities'

export function InterviewInvite({ emailId }: InterviewInviteContent) {
  return (
    <div className="flex items-center gap-x-2">
      <Mail className="text-primary" size={16} />
      <Text key={emailId} size="xs" weight="semibold">
        email
      </Text>
    </div>
  )
}
