import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'
// import { TanStackRouterDevtools } from '@tanstack/router-devtools'

import { TooltipProvider } from '@/components/ui/tooltip'
import { AuthContextProps } from '@/providers/auth-provider'

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
  auth: AuthContextProps
}>()({
  component: () => (
    <TooltipProvider delayDuration={300}>
      <Outlet />
      {/* <TanStackRouterDevtools /> */}
      <ReactQueryDevtools />
    </TooltipProvider>
  ),
})
