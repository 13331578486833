import { z } from 'zod'

export const emailsToSendCustomSchema = z.object({
  applicationId: z.string(),
  body: z.string().trim().min(1, { message: 'Body is required.' }),
  emailTemplateId: z.string().optional(),
  subject: z.string().trim().min(1, { message: 'Subject is required.' }),
})

export type EmailsToSendCustomValues = z.infer<typeof emailsToSendCustomSchema>
