import { Children, ComponentProps } from 'react'

import { ReactNode } from '@tanstack/react-router'

import { Avatar, AvatarFallback } from './avatar'

interface AvatarGroupProps {
  children: ReactNode[]
  max?: number
  size?: ComponentProps<typeof Avatar>['size']
}

export function AvatarGroup({ children, max = 3, size }: AvatarGroupProps) {
  const avatars = Children.toArray(children)
  const maxShow = max === avatars.length ? max : max - 1
  const extraAvatars = avatars.length - maxShow

  return (
    <div className="flex -space-x-2">
      {avatars.slice(0, maxShow).map((avatar) => avatar)}
      {extraAvatars > 0 && (
        <Avatar size={size}>
          <AvatarFallback className="border-2 border-background font-semibold">+{extraAvatars}</AvatarFallback>
        </Avatar>
      )}
    </div>
  )
}
