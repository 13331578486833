import { useState } from 'react'

import { PlusSquare } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'

import { CreateForm } from './create-form'

export function InterviewsDrawer() {
  const [open, onOpenChange] = useState(false)

  return (
    <Sheet onOpenChange={onOpenChange} open={open}>
      <SheetTrigger asChild>
        <Button>
          <PlusSquare size={16} />
          New interview
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>New Interview</SheetTitle>
        </SheetHeader>
        <CreateForm onClose={() => onOpenChange(false)} />
      </SheetContent>
    </Sheet>
  )
}
