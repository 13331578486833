import * as z from 'zod'

export const loginSchema = z.object({
  email: z.string().email({
    message: 'Email is required',
  }),
  password: z.string().min(1, {
    message: 'Password is required',
  }),
})

export type LoginValues = z.infer<typeof loginSchema>

export const forgotPasswordSchema = z.object({
  email: z.string().email({
    message: 'Email is required',
  }),
})

export type ForgotPasswordValues = z.infer<typeof forgotPasswordSchema>

const passwordSchema = z
  .string()
  .min(8, 'Password must be at least 8 characters long')
  .regex(/[a-z]/, 'Password must contain at least one lowercase letter')
  .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
  .regex(/\d/, 'Password must contain at least one number')
  .regex(/[\W_]/, 'Password must contain at least one symbol')

export const resetPasswordSchema = z
  .object({
    confirmation: z.string(),
    email: z.string().email({
      message: 'Please enter a valid email address.',
    }),
    password: passwordSchema,
    token: z.string().min(1, {
      message: 'Token is required',
    }),
  })
  .refine((data) => data.password === data.confirmation, {
    message: 'Passwords must match.',
    path: ['confirmation'],
  })

export type ResetPasswordValues = z.infer<typeof resetPasswordSchema>

export const createPasswordSchema = z
  .object({
    confirmation: z.string(),
    email: z.string().email({
      message: 'Please enter a valid email address.',
    }),
    password: passwordSchema,
    token: z.string().min(1, {
      message: 'Token is required',
    }),
  })
  .refine((data) => data.password === data.confirmation, {
    message: 'Passwords must match.',
    path: ['confirmation'],
  })

export type CreatePasswordValues = z.infer<typeof createPasswordSchema>
