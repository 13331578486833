import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { Title } from '@/components/ui/title'

export const Route = createFileRoute('/_authenticated/tasks')({
  component: TasksIndex,
})

function TasksIndex() {
  return (
    <PageContent>
      <Title>Tasks view</Title>
    </PageContent>
  )
}
