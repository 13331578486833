import { Text } from '@/components/ui/text'
import { InterviewApplication, InterviewStatus } from '@/types/application/interview'
import { getFormattedDateTime } from '@/utils/date'

import { Interview } from './interview'

interface NoteProps {
  interview: InterviewApplication['interviews'][0]
}

export function Note({ interview }: NoteProps) {
  const isCancelOrRescheduled = ['CANCELED', 'RE_SCHEDULED'].includes(interview.status)

  return (
    <div className="flex flex-col gap-y-2">
      <div className="bg-background p-1">
        {interview.status === 'TO_SCHEDULE' && (
          <>
            <Text align="center" size="sm">
              Not yet scheduled
            </Text>
            <Text align="center" size="xs" weight="semibold">
              email sent to applicant
            </Text>
          </>
        )}
        {interview.status !== 'TO_SCHEDULE' && (
          <Text align="center" lineThrough={isCancelOrRescheduled} size="sm">
            {getFormattedDateTime(interview.scheduledAt)}
          </Text>
        )}
        {isCancelOrRescheduled && (
          <Text align="center" size="xs" weight="semibold">
            {InterviewStatus[interview.status]}
          </Text>
        )}
      </div>
      {!isCancelOrRescheduled && <Interview interview={interview} />}
    </div>
  )
}
