import {
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  FormatDistanceToNowStrictOptions,
  isToday as isTodayDateFns,
  subDays,
} from 'date-fns'

export function getDistanceToNowStrict(date: string, options?: FormatDistanceToNowStrictOptions) {
  const now = new Date()
  const distanceInSeconds = Math.abs((new Date(date).getTime() - now.getTime()) / 1000)

  if (distanceInSeconds < 60) {
    return 'now'
  }
  return formatDistanceToNowStrict(date, options)
}

export function getFormattedDate(date: string) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function getFormattedDateTime(date: string | Date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

export function isAfterToday(date: string) {
  return isAfter(new Date(date), new Date())
}

export function isToday(date: string) {
  return isTodayDateFns(new Date(date))
}

export function isBeforeToday(date: string | Date) {
  return isBefore(date, subDays(Date.now(), 1))
}
