import { MailIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet'
import { useHelpers } from '@/hooks/use-helpers'

import { ContactForm } from './contact-form'

export function ContactDrawer() {
  const { isOpen, setIsOpen } = useHelpers()

  return (
    <Sheet onOpenChange={setIsOpen} open={isOpen}>
      <Button className="self-start" onClick={() => setIsOpen(true)} variant="outline">
        <MailIcon size={16} />
        Contact
      </Button>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Send Email</SheetTitle>
        </SheetHeader>
        <ContactForm onClose={() => setIsOpen(false)} />
      </SheetContent>
    </Sheet>
  )
}
