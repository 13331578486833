import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { ActivitiesApplication, ActivitiesLastApplication } from '@/types/application/activities'
import { Applicant } from '@/types/application/applicant'
import { ApplicantInfo } from '@/types/application/applicant-info'
import { CreateApplicationCommentBody } from '@/types/application/comments'
import { CreateApplicationBody } from '@/types/application/create'
import {
  ApplicationsDocument,
  UpdateApplicationsDocumentBody,
  UpdateApplicationsDocumentResponse,
} from '@/types/application/documents'
import { CreateApplicationInterviewBody, InterviewApplication } from '@/types/application/interview'
import { JobStageApplication } from '@/types/application/job-stages'
import { UpdateRefuseApplicationBody } from '@/types/application/refuse-application'
import { UpdateApplicationsApplicantBody } from '@/types/application/update-applicant'
import { ApplicationsUpdateStageBody } from '@/types/application/update-stage'

export function updateApplicationsStage(applicationId: string, body: ApplicationsUpdateStageBody) {
  return put<ApplicationsUpdateStageBody, { id: string }>(`/applications/${applicationId}/update-stage`, body)
}

export function createApplication(body: CreateApplicationBody) {
  return post<CreateApplicationBody, { id: string }>('/applications', body)
}

export function getApplicationsApplicant(applicationId: string) {
  return get<Applicant>(`/applications/${applicationId}/applicant`)
}

export function getApplicationJobStages(applicationId: string) {
  return get<JobStageApplication>(`/applications/${applicationId}/job-stages`)
}

export function getApplicationsApplicantInfo(applicationId: string) {
  return get<ApplicantInfo>(`/applications/${applicationId}/applicant-info`)
}

export function updateApplicationsApplicant(applicationId: string, body: UpdateApplicationsApplicantBody) {
  return put<UpdateApplicationsApplicantBody, { id: string }>(`/applications/${applicationId}/update-applicant`, body)
}

/*
 ** interviews
 */
export function getApplicationsInterviews(applicationId: string) {
  return get<InterviewApplication>(`/applications/${applicationId}/interviews`)
}

export function createApplicationsInterviews(applicationId: string, body: CreateApplicationInterviewBody) {
  return post<CreateApplicationInterviewBody, { id: string }>(`/applications/${applicationId}/interviews`, body)
}

/*
 ** activities
 */
export function getApplicationsActivities(applicationId: string) {
  return get<ActivitiesApplication[]>(`/applications/${applicationId}/activities`)
}

export function getApplicationsLastActivities(applicationId: string) {
  return get<ActivitiesLastApplication>(`/applications/${applicationId}/activities/last`)
}

/*
 ** comments
 */
export function createApplicationsComment(applicationId: string, body: CreateApplicationCommentBody) {
  return post<CreateApplicationCommentBody, { id: string }>(`/applications/${applicationId}/comments`, body)
}

/*
 ** documents
 */
export function getApplicationsDocuments(applicationId: string) {
  return get<ApplicationsDocument[]>(`/applications/${applicationId}/documents`)
}

export function createApplicationsDocuments(applicationId: string, body: FormData) {
  return post<FormData, { url: string }>(`/applications/${applicationId}/documents`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function updateApplicationsDocuments(
  applicationId: string,
  documentId: string,
  body: UpdateApplicationsDocumentBody,
) {
  return put<UpdateApplicationsDocumentBody, UpdateApplicationsDocumentResponse>(
    `/applications/${applicationId}/documents/${documentId}`,
    body,
  )
}

/*
 ** refuse-application
 */
export function updateApplicationsRefuseApplication(applicationId: string, body: UpdateRefuseApplicationBody) {
  return put<UpdateRefuseApplicationBody, { id: string }>(`/applications/${applicationId}/refuse-application`, body)
}
